import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

// imported react hooks and packages
import { CSSTransition } from 'react-transition-group';

// custom components
import Backdrop from '../Backdrop/Backdrop';
import Button from '../Button/Button.jsx';

// custom icons
import { VscChromeClose } from 'react-icons/vsc';

// custom css
import './Modal.css';
import { images } from '../../../assets'

const WarningModalOverlay = props => {


  useEffect(() => {
    return
  }, [props.refresh])



  const content = (
    <div className={`dashboard-modal px-2 md:px-4
    ${props.loading && "dashboard-modal-loading"} 
    ${props.className} ${props.modalTopRight && 'dashboard-modal-top-right'}`}  
    style={props.style}>
      {/* <header className={`dashboard-modal__header ${props.headerClass}`}>
        <h2>{props.header}</h2>
      </header> */}
      <form
        onSubmit={
          props.onSubmit ? props.onSubmit : event => event.preventDefault()
        }
      >
        <div className={`dashboard-modal__content ${props.contentClass}`}>
            <div className="flex justify-center items-center flex-col">
                <img src={images.infoIcon["src"]} alt={images.infoIcon["alt"]} />

                <h4 className='my-4 text-primary-grey'>{props.title}</h4>

                <p className={`text-center text-secondary-grey ${props.textClassName}`}>{props.text}</p>
            </div>
          {props.children}
        </div>
        <footer className={`dashboard-modal__footer w-full flex justify-center items-center ${props.footerClass}`}>
          <Button border shadow className={`cancel-box ${props.cancelClassName}`} onClick={props.onCancel}>{props.CancelText}</Button>
          <Button bgPrimary textWhite className={`ml-3 ${props.submitClassName}`} onClick={props.onSubmitButton}>{props.SubmitText}</Button>
        </footer>
      </form>
      <div className={`absolute top-4 right-4 ${props.closeButton ? 'block':'hidden'}`}>
          <button onClick={props.onCancel}>
              <VscChromeClose
                  className='text-base text-dashboard-black rounded pointer'/>
          </button>
      </div>
    </div>
  );
  return ReactDOM.createPortal(content, document.getElementById('modal-hook'));
};

const WarningModal = props => {
  return (
    <>
      {props.show && <Backdrop onClick={props.onCancel} fade={true}/>}
      <CSSTransition
        in={props.show}
        mountOnEnter
        unmountOnExit
        timeout={200}
        classNames="dashboard-modal"
      >
        <WarningModalOverlay {...props} />
      </CSSTransition>
    </>
  );
};

export default WarningModal;
