import DashboardOverview from './DashboardOverview/Index';
import Settings from './Settings/Index'
import Orders from './Orders/Index'
import Events from './Events/Index'
import OrdersDetail from './OrdersDetail/Index'
import CreateEvent from './CreateEvent/Index'
import FundRequest from './FundRequest/Index'
import FundRequestDetail from './FundRequestDetail/Index'
import Payments from './Payments/Index'
import Ticketers from './Ticketers/Index'


const dashboard = {
    DashboardOverview,
    Settings,
    Orders,
    Events,
    OrdersDetail,
    CreateEvent,
    FundRequest,
    FundRequestDetail,
    Payments,
    Ticketers
}

export default dashboard