import React from 'react';
import { Link } from 'react-router-dom';
import './Button.css';

const Button = ({
  href,
  to,
  exact,
  textWhite,
  bgPrimary,
  shadow,
  className,
  type,
  onClick,
  disabled,
  children,
}) => {
  const buttonClass = `button ${textWhite ? 'text-white' : 'text-primary-black'} 
    ${bgPrimary && 'bg-primary'}  
    ${shadow && 'button-shadow'} 
    ${className}`;

  if (href) {
    return (
      <a className={buttonClass} href={href}>
        {children}
      </a>
    );
  }

  if (to) {
    return (
      <Link to={to} exact={exact} className={buttonClass}>
        {children}
      </Link>
    );
  }

  return (
    <button
      className={buttonClass}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;