
import { useContext, useEffect, useState } from 'react'
import { useCallback } from 'react';
import { useHttpClient } from '../hooks/less-http-hook';
import { AuthContext } from '../context/auth-context';


export const useProfile = () =>{
    const {isLoading, error, sendRequest} = useHttpClient();

    const [userProfile, setUserData] = useState()
    const [profileLoading, setProfileLoading] = useState(isLoading)

    useEffect(() => {
        setProfileLoading(isLoading)
    },[isLoading])
    

    const auth = useContext(AuthContext)

    const profileData = useCallback( async(params) => {
        try {
            // console.log("auth.token => ", auth.token)
            const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}organiser/details${params}`, "GET", null, {Authorization: 'Bearer '+auth.token});
            
            if(responseData){
                setUserData(responseData?.data?.organiser)
                return responseData?.data?.organiser
            }

            return responseData?.data?.organiser
            
        } catch (err) {
            console.log(error)
        }
    },[auth.token, error, sendRequest])

    const updateProfileData = useCallback( async(data) => {

        try {
            const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}organiser/update-profile`, "POST", data, {Authorization: 'Bearer '+auth.token});
            
            return responseData.data
        
        } catch (err) {
            console.log(error)
        }
    },[auth.token, error, sendRequest])

    const updateImageData = useCallback( async(data) => {

        try {
            const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}organiser/change-image`, "POST", data, {Authorization: 'Bearer '+auth.token});
            return responseData.data
        
        } catch (err) {
            console.log(error)
        }
    },[auth.token, error, sendRequest])

    

    return {userProfile, profileLoading, profileData, updateProfileData, updateImageData}
}