import images from "../assets";
import { BookIcon, StartUpIcon, ThreeSquareIcon } from "../components/Reusables/SvgIcons";

export const navItems = [
  {
    id: 1,
    title: "Home",
    path: "/",
    nName: "nav-item",
    sName: "sidebar-item"
  },
  {
    id: 2,
    title: "About Us",
    path: "/about",
    nName: "nav-item",
    sName: "sidebar-item"
  },
  {
    id: 3,
    title: "Browse",
    path: "/browse",
    nName: "nav-item",
    sName: "sidebar-item"
  },
  {
    id: 4,
    title: "Contact Us",
    path: "/policies",
    nName: "nav-item",
    sName: "sidebar-item"
  }
];

export const footerItems = [
  {
    id: 1,
    title: "About Us",
    path: "/about",
    nName: "nav-item",
    sName: "sidebar-item"
  },
  {
    id: 2,
    title: "Contact Us",
    path: "/policies",
    nName: "nav-item",
    sName: "sidebar-item"
  },
  {
    id: 3,
    title: "Term of Use",
    path: "/termsofuse",
    nName: "nav-item",
    sName: "sidebar-item"
  },
  {
    id: 4,
    title: "Privacy Policy",
    path: "/privacypolicy",
    nName: "nav-item",
    sName: "sidebar-item"
  },
];

