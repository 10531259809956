import React, { useState } from 'react'
import {RiSearchLine} from 'react-icons/ri'

import "./ticket.css"
import EventTable from '../../Reusables/Tables/EventTable';

function Index() {

    const [searchTerm, setSearchTerm] = useState('');

    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
      };
    
      const handleSubmit = (event) => {
        event.preventDefault();
        
      };
    
    return(
        <div className='my-10 mx-12 '>
        <h3 className='text-main-black font-medium ml-4 mb-4 text-2xl '>Events</h3>
        <div className=' bg-white rounded-md mb-4'>
            <div className="flex flex-col md:flex-row items-start md:items-center justify-between border-b border-border-color px-6 py-4">
                <div>
                    <h3 className='text-black-text font-medium'>All events</h3>
                </div>
        
                <form onSubmit={handleSubmit}>
                    <div className="flex items-center px-2 w-full search_card bg-white ">
                        
                        <input
                            type="search"
                            value={searchTerm}
                            onChange={handleInputChange}
                            placeholder="Search by event ID, date, ticket type"
                            className="p-3 rounded-lg outline-none border-none"
                        />
                        <RiSearchLine classname="text-lg  text-primary-grey"/>
                    </div>
                    
                </form>

            </div>

            <div className='w-full flex justify-center items-center'>
                <EventTable/>
            </div>
        </div>
    </div>
    )
}

export default Index