export const PAYMENTS_COLUMNS = [
  {
    Header: 'Amount',
    accessor: 'amount',
    
  },
  {
    Header: 'Payment For',
    accessor: 'payment',
    
  },
  {
    Header: 'Ticket Issued',
    accessor: 'ticket',
    
  },
  {
    Header: 'Status',
    accessor: 'status',
    
  },
  {
    Header: 'Date',
    accessor: 'date',
    
  }
  
]

export const EVENT_COLUMNS = [
  {
    Header: 'Event Name',
    accessor: 'eventName',
  },
  {
    Header: 'Event Date',
    accessor: 'eventDate',
  },
  {
    Header: 'Time',
    accessor: 'time',
  },
  {
    Header: 'Location',
    accessor: 'location',
  },
  {
    Header: 'Date Created',
    accessor: 'dateCreated',
  },
  {
    Header: 'Issued',
    accessor: 'issued',
  },
  {
    Header: 'Tickets',
    accessor: 'tickets',
  }
]

export const ORDERS_COLUMNS = [
  {
    Header: 'Customer Name',
    accessor: 'customerName',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Phone',
    accessor: 'phone',
  },
  {
    Header: 'Event',
    accessor: 'event',
  },
  {
    Header: 'Ticket',
    accessor: 'ticket',
  },
  {
    Header: 'Order Number',
    accessor: 'orderNumber',
  },
  {
    Header: 'Order Date',
    accessor: 'orderDate',
  }
]

export const REFUND_COLUMNS = [
  {
    Header: 'Event',
    accessor: 'event',
  },
  {
    Header: 'Ticket',
    accessor: 'ticket',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Amount',
    accessor: 'amount',
  },
  {
    Header: 'Order Number',
    accessor: 'orderNumber',
  },
  {
    Header: 'Request Date',
    accessor: 'requestDate',
  },
  {
    Header: 'Status',
    accessor: 'status',
  }
]

export const SALES_COLUMNS = [
  {
    Header: 'Customer Name',
    accessor: 'customerName',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Phone',
    accessor: 'phone',
  },
  {
    Header: 'Event',
    accessor: 'event',
  },
  {
    Header: 'Ticket',
    accessor: 'ticket',
  },
  {
    Header: 'Order Number',
    accessor: 'orderNumber',
  },
  {
    Header: 'Order Date',
    accessor: 'orderDate',
  }
]

export const WITHDRAWAL_COLUMNS = [
  {
    Header: 'Amount',
    accessor: 'amount',
  },
  {
    Header: 'Date',
    accessor: 'date',
  },
  {
    Header: 'Transferred to ',
    accessor: 'transferredTo ',
  },
  {
    Header: 'Transaction ID',
    accessor: 'transactionID',
  },
  {
    Header: 'Fees',
    accessor: 'fees',
  },
  {
    Header: 'Amount credited',
    accessor: 'amountCredited',
  },
  {
    Header: 'Status',
    accessor: 'status',
  }
]

export const TICKETS_COLUMNS = [
  {
    Header: 'Event Name',
    accessor: 'eventName',
  },
  {
    Header: 'Event ID',
    accessor: 'eventID',
  },
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Phone',
    accessor: 'phone',
  },
  {
    Header: 'Date Created',
    accessor: 'dateCreated',
  },
  {
    Header: 'Total Scanned',
    accessor: 'totalScanned',
  }
]