import React from 'react'
import { MdOutlineArrowBack } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { images } from '../../../assets'

function CheckComp() {
  return (
    <div className='mx-auto' data-aos="fade-down">
            <div className='text-center'>
                <img src={images.mailIcon["src"]} alt={images.mailIcon["alt"]} className="mx-auto mb-6"/>
                <h3 className='font-bold text-xl md:text-2xl text-black-text mb-2'>Check your email</h3>
                
                <p className='text-black-gray text-sm md:text-base'>
                        We sent a password reset link to email@tickit.com
                </p>
            </div>

            <div className='my-8 flex__center'>
                <a href="mailto:hello@tickit.io?subject=Making Inquiries at Tickit&body=Hi Tickit"
                    target="_blank" rel="noopener noreferrer" 
                    className='px-20 py-3  bg-primary focus:outline-primary  rounded-lg mx-auto text-white'>
                        Please open email app
                </a>
            </div>

            <p className='text-black-gray text-center mt-3 text-sm md:text-base'>Didn’t receive the email? 
            <button className='text-primary-green font-medium'> Click to resend</button></p>
            <Link to='/login' className='flex__center mt-4 font-semibold cursor-pointer text-black-text'>
                <MdOutlineArrowBack/>
                <p className='text-sm'>Back to log in</p>
            </Link>


            
        </div>
  )
}

export default CheckComp