import React from 'react'
import { useContext } from 'react'
import { DataContext } from '../../../context/DataContext'

//custom components


//sections
import FirstSection from './FirstSection'
import BoardCard from '../../Reusables/Cards/BoardCard'
import { AiOutlineBank } from 'react-icons/ai'




function Index() {
  const data = useContext(DataContext).userData

  
  return (
    <div className='w-full min-h-screen bg-dashboard-bg'>

        <div className={`my-2 md:my-6 mb-8 md:mb-10 flex flex-col md:flex-row 
        gap-2 md:gap-0 justify-between items-start md:items-center`}>
          <div>
            <h1 className='text-xl md:text-2xl font-semibold text-black-tertiary'>
              Escrow
            </h1>
          </div>
        </div>

        <div className={`my-2 md:my-6 mb-8 md:mb-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6`}>
          <BoardCard title="Ongoing"
              amount="0"
              currency
              color="bg-primary-green"
              icon={(<AiOutlineBank className='text-primary-green text-2xl'/>)}/>
          
          <BoardCard title="AVAILABLE WITHDRAWAL"
              amount="0"
              currency
              color="bg-primary-blue"
              icon={(<AiOutlineBank className='text-primary-blue text-2xl'/>)}/>

          <BoardCard title="TOTAL TRANSFERED"
              amount="0"
              currency
              color="bg-primary-purple"
              icon={(<AiOutlineBank className='text-primary-purple text-2xl'/>)}/>
        </div>



        <FirstSection data={data}/> 
        
    </div>
  )
}

export default Index