import React, { useState } from 'react'
import Navigation from './Navigation'
import Profile from './Profile'
import Password from './Password'
import History from './History'
import BreadCrumbNavigation from '../BreadCrumbNavigation'
  
function Index() {  

    const [tab, setTab] = useState("profile")

    const clickNavHandler = (text) => {
        setTab(text)
    }

    console.log(tab)

  return (
    <>
    <BreadCrumbNavigation path="settings" nav="Edit Profile"/>
    <div className='grid grid-cols-1  md:grid-cols-4 xl:grid-cols-5 gap-4'>
        <div>
            <Navigation navText="Profile" nav="profile" tab={tab} clickNavHandler={clickNavHandler}/>
            <Navigation navText="Password" nav="password" tab={tab} clickNavHandler={clickNavHandler}/>
            {/* <Navigation navText="Transaction History" nav="history" tab={tab} clickNavHandler={clickNavHandler}/> */}
        </div>

        <div className='md:col-span-3 xl:col-span-4 bg-white'>
            {tab === "profile" && <Profile/>}
            {tab === "password" && <Password/>}
            {/* {tab === "history" && <History/>} */}
        </div>
    </div>
    </>
  )
}

export default Index