import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { images } from '../../assets'

import { AuthContext } from '../../context/auth-context'
import Modal from '../../UI_Elements/Modal/Modal'
import '../auth.css'

function AuthVerified(props) {

  const auth = useContext(AuthContext)

  const history = useNavigate()

  const authVerifiedHandler = () => {
    auth.login(props.token)
    history("/")
  }
  return (
    <Modal show={props.verified} fade loading>
        <div className='flex flex-col justify-center items-center text-center w-fit mx-auto'>
            <div className='w-24 h-24 flex__center'>
                <img src={images.successIcon["src"]} alt={images.successIcon['alt']} className=" check-bg rounded-full "/>
            </div>
            <h3 className='mb-2 mt-2 font-semibold text-primary-black'>Email verified</h3>
            <p className='text-primary-black-70 text-sm'>Your account has been successfully created. Click below to log in.</p>
            <button onClick={authVerifiedHandler} className='w-full py-3 bg-primary text-white rounded-lg mt-4'>Log In</button>
        </div>
    </Modal>
  )
}

export default AuthVerified