import React, {useMemo, useEffect, useState, useContext} from 'react'

import {useNavigate} from 'react-router-dom'

// REACT HOOKS
import {useTable, usePagination} from 'react-table'
import {AuthContext} from '../../../context/auth-context';


// DATA
import {ORDERS_COLUMNS} from '../../../util/columns/columns';
import { transactionEndpoints } from '../../../api';


//custom components
import Loader from '../loading/Loader';
import TablePagination from '../TablePagination/TablePagination';


// custom icons
import {MdKeyboardArrowRight} from 'react-icons/md'

// custom css
import './tables.css'
import moment from 'moment';
import { useApi } from '../../../api/api-hook';
// import Pill from '../pills/Index';




function OrdersTable(props) {    

    const auth = useContext(AuthContext)
    let dataStorage = null
    if(localStorage.getItem('profileData')){
        dataStorage = JSON.parse(localStorage.getItem('profileData'))
    }

    const history = useNavigate()

    // const [eventModal, setEventModal] = useState(false)

    const [tableData, setTableData] = useState();

    const [cellData , setCellData] = useState([]);

    const [detailsData, setDetailsData] = useState({
        "current_page" : '1',
        "page_number":"15",
        "status":"0",
        "search_text":""
    })

    const [pages, setPages] = useState([]);

    // const [refresh, setRefresh] = useState(false);

    const { getData, error, isLoading} = useApi()

    useEffect(() => {
        setTableData(null)

        const formData = new FormData()
        formData.append('page_number',detailsData.page_number);
        formData.append('status',detailsData.status);

            
            const fetchData = async() => {
            
                try {
                    const response = await getData(transactionEndpoints['LIST-TRANSACTIONS-PAGINATED'], null,`?organiser_id=${dataStorage?.organiser?.id}&page_size=${detailsData.page_number}&page_number=${detailsData.current_page}`);
    
                    setTableData(response.data);
                    // setPages(responseData.claim.link)
    
                    if(response){
                        setCellData([])
                        for(let file of response.data.transactions){
                            setCellData((cell)=>[...cell, {
                                "id":file.id,
                                "orderDate": moment(file?.confirmed_at).format('DD MMM YYYY') || "",
                                "time": moment(file?.start_time).format('h.mm A') || "",
                                "location": `${file?.address} ${file?.state}` || "",
                                "orderNumber": file?.reference || "",
                                "status": file?.status || "",
                                "event": file.location ? file?.location?.name || "" : file?.event?.name || "",
                                "amount": file?.amount || 0,
                                "customerName": `${file?.owner?.first_name || ""} ${file?.owner?.last_name || ""}`,
                                "email": file?.owner?.email_address || "",
                                "phone": file?.owner?.phone_number || "",
                                "ticket": file?.tickets || 0
                            }])
                        }
                    }
                } catch (err) {
                    console.log(error)
                }
            };
            fetchData();
    
        }, [getData, error, auth, detailsData, dataStorage.organiser.id]);





    const pageChangeHandler = (url) =>{
        if(url === null){
            return
        }

        let pageCustom = url.split("=")[1];

        setDetailsData({
            ...detailsData,
            "current_page":pageCustom.toString()
        })
    }

    const pageSizeChangeHandler = (number) => {
        setDetailsData({
            ...detailsData,
            "page_number":number.toString()
        })
    }

    const eventHandler = (id) => {
        history(id.toString())
        // setEventModal(!eventModal)
    }
    // const onCancelEventHandler = () => {
    //     setEventModal(false)
    // }



    const columns = useMemo(() => ORDERS_COLUMNS, [])
    const data = useMemo(() => cellData, [cellData])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable({
        columns,
        data,
        initialState: {
            pageIndex: 0
        }
    }, usePagination)

    return (
        <>

        {!isLoading && cellData.length === 0 && 
        <div className='py-16 px-6 w-full flex justify-center items-center'>
            <p className='text-black-alt text-sm text-center'>No transaction has been created yet</p>
        </div>}

        {isLoading && <Loader/>}

        {cellData.length !== 0 &&
        
        <div className="bg-white pb-8 table__borderless overflow-x-auto w-full">
             
            <table {...getTableProps()} className="w-full text-left">
                <thead className='border-b border-border-color w-full'>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} className="">
                            {headerGroup
                                .headers
                                .map(column => (
                                    <th {...column.getHeaderProps()} 
                                    className={`text-xs md:text-sm text-primary-black-70 capitalize
                                    whitespace-nowrap p-4 px-6`}>
                                        {column.render('Header')}
                                    </th>
                                ))}
                            
                            {/* <th className="text-xs md:text-sm text-primary-black-70 font-medium whitespace-nowrap p-4">Status</th> */}
                            {/* <th className="text-xs md:text-sm text-primary-black-70 font-medium whitespace-nowrap p-4"></th> */}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, index) => {
                        prepareRow(row)

                        return (
                            <tr {...row.getRowProps()} 
                            key={index}
                            className={`${index%2 === 0 && "bg-odd-row"} font-sora`}>
                               
                                {row
                                    .cells
                                    .map(cell => {
                                        
                                        return <td {...cell.getCellProps()} 
                                        className={`border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm 
                                        whitespace-nowrap p-4 text-left `}>
                                            {cell.render('Cell')}</td>
                                    })}
                                
                                {/* <td className={`border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm 
                                        whitespace-nowrap p-4 text-left `}>
                                    
                                    <Pill
                                    color={`${row.original.status === "2" ? "green" : row.original.status === "1" ? "yellow" : "red"}`}   
                                    text={`${row.original.status === "2" ? "Active" : row.original.status === "1" ? "Pending" : "Banned"}`}/>
                                </td> */}

                                {/* <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4">
                                    <MdKeyboardArrowRight 
                                    className='text-primary-black text-lg cursor-pointer' 
                                    onClick={() => eventHandler(row.original.userId)}/>
                                </td> */}
                            </tr>
                        )
                    })}


                    {/* <tr className='bg-odd-row font-sora'>
                        <td className="border-t-0 px-6  align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4 text-left ">
                            <p className='text-primary-grey'>L029333W</p>
                        </td>

                        <td className="px-6 align-middle  text-sm whitespace-nowrap p-4">
                            <p className='text-primary-grey'>GTBANK Fashion Week</p>
                        </td>
                        

                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4">
                            <p className='text-primary-grey'>Thu, Apr 19</p>
                        </td>

                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm truncate whitespace-nowrap p-4">
                            <p className='text-primary-grey'>₦ 5,000</p>
                        </td>

                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4">
                            <p className='text-primary-grey'>1</p>
                        </td>

                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4">
                            <p className='text-primary-grey'>156093</p>
                        </td>

                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4">
                            <p className='text-primary-grey'>1/12/2022</p>
                        </td>


                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4">
                            <MdKeyboardArrowRight className='text-primary-black text-lg cursor-pointer' onClick={() => eventHandler("123")}/>
                        </td>
                    </tr> */}
                </tbody>

            </table>
            <div className='w-full dash-h-1 bg-primary-black opacity-50'></div>
            {tableData && (
                <TablePagination 
                current_page = {tableData.current_page}
                last_page = {tableData.last_page}
                prev_page_url = {tableData.prev_page_url}
                next_page_url = {tableData.next_page_url}
                pages = {pages}
                per_page = {tableData.per_page}
                first_page_url = {tableData.first_page_url}
                last_page_url={tableData.last_page_url}
                pageChangeHandler={pageChangeHandler}
                pageSizeChangeHandler={pageSizeChangeHandler}/>
           )}

            
            
        </div>
        }
        </>              
    )
}

export default OrdersTable