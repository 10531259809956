import { useHttpClient } from '../hooks/less-http-hook'
import { AuthContext } from '../context/auth-context'
import { useContext, useEffect, useState } from 'react'
import { useCallback, useMemo } from 'react';


export const useTransactions = () =>{
    const {isLoading, error, sendRequest} = useHttpClient();

    const [statusLoading, setStatusLoading] = useState(isLoading)

    const eventEndpoints = useMemo(() => {
        return {
          'LIST-TRANSACTIONS': '/organiser/list-transactions',
          'LIST-TRANSACTIONS-PAGINATED': '/organiser/list-transactions'
        };
      }, []);

    useEffect(() => {
        setStatusLoading(isLoading)
    },[isLoading])
    

    const auth = useContext(AuthContext)

    const postTransactions = useCallback( async(id,data) => {

        try {
            const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}${eventEndpoints[id]}`, "POST", data, {});
            return responseData.data
        
        } catch (err) {
            console.log(error)
        }
    },[ error, sendRequest, eventEndpoints])

    const updateTransactions = useCallback( async(id,data) => {

        try {
            const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}${eventEndpoints[id]}`, "PATCH", data, {Authorization: 'Bearer '+auth.token});
            return responseData.data
        
        } catch (err) {
            console.log(error)
        }
    },[auth.token, error, sendRequest, eventEndpoints])

    const getTransactions = useCallback( async(id,params='') => {

        try {
            const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}${eventEndpoints[id]}${params}`, "GET", null, {});
            return responseData.data
        
        } catch (err) {
            console.log(error)
        }
    },[ error, sendRequest, eventEndpoints])

    

    return {transactionsLoading: statusLoading, postTransactions, updateTransactions, getTransactions}
}