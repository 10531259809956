import React, {useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'

// custom components
import Input from '../UI_Elements/Input/Input'

// custom util function
import {VALIDATOR_EMAIL, VALIDATOR_REQUIRE} from '../util/validators'
import PhoneInput from 'react-phone-input-2'
import {MdOutlineArrowBack} from 'react-icons/md'
import { TickitIcon } from '../components/Reusables/SvgIcons';

// custom hooks
import {useForm} from '../hooks/form-hook'


//css scripts
import 'react-phone-input-2/lib/style.css'


function Signup() {
    const [phone,
        setPhone] = useState("")
    const [isphonevalid, setisphonevalid] = useState(true)
    
    const history = useNavigate()
    

    const [formState,
        inputHandler] = useForm({

        email: {
            value: '',
            isValid: false
        },
        firstname: {
            value: '',
            isValid: false
        },
        lastname: {
            value: '',
            isValid: false
        }
    }, false);

    const authSubmitHandler = async(e) =>{
        e.preventDefault();
        console.log(phone)
        if(phone.length < 3){
            return setisphonevalid(false)
        }

        try {
            // const data = JSON.stringify({
            //     firstname: formState.inputs.firstname.value,
            //     lastname: formState.inputs.lastname.value,
            //     phone_number: phone,
            //     email: formState.inputs.email.value,
            // })
    
    
            history(`/createpassword?firstname=${formState.inputs.firstname.value}&lastname=${formState.inputs.lastname.value}&phonenumber=${phone.trim().toString()}&email=${formState.inputs.email.value}`)
        }
        catch(err){
            console.log(err)
        }

        
        
        
    }

    return (
        <div className='auth_bg flex justify-center pt-16 '>
            <div className='mx-auto' data-aos="fade-left">
                <div className='text-center'>
                    <Link to="/" className='mb-4 flex items-center justify-center'>
                        <TickitIcon width="100"/>
                    </Link>
                    <h3 className='font-semibold text-xl md:text-2xl text-black-text mb-2'>Create Organizer Account</h3>
                    <p className='text-black-text mt-4 text-sm md:text-base'>Just one more steps to complete your account</p>
                </div>

                <form className='my-6 px-4 md:px-0 md:w-96' onSubmit={authSubmitHandler}>
                    <Input
                        type="text"
                        placeholder='Enter your first name'
                        label="First Name*"
                        labelClassName={`text-sm text-black-text mb-2 font-semibold form_login_label`}
                        id="firstname"
                        validators={[VALIDATOR_REQUIRE()]}
                        errorText="Please enter a valid first name"
                        onInput={inputHandler}
                        divClassName={`mb-3 `}
                        containerClassName={`relative `}
                        className={`appearance-none 
                        input-form p-3 w-full text-black-text focus:outline-none 
                        focus:ring-none focus:border-primary focus:z-10 sm:text-sm`}/>

                    <Input
                        type="text"
                        placeholder='Enter your last name'
                        label="Last Name*"
                        labelClassName={`text-sm text-black-text mb-2 font-semibold form_login_label`}
                        id="lastname"
                        validators={[VALIDATOR_REQUIRE()]}
                        errorText="Please enter a valid last name"
                        onInput={inputHandler}
                        divClassName={`mb-3 `}
                        containerClassName={`relative `}
                        className={`appearance-none input-form p-3 w-full text-primary-black 
                        focus:outline-none focus:ring-none focus:border-primary focus:z-10 sm:text-sm`}/>

                    <Input
                        type="email"
                        placeholder='Enter your email'
                        label="Email*"
                        labelClassName={`text-sm text-black-text mb-2 font-semibold form_login_label`}
                        id="email"
                        validators={[VALIDATOR_EMAIL()]}
                        errorText="Please enter a valid email"
                        onInput={inputHandler}
                        divClassName={`mb-3 `}
                        containerClassName={`relative `}
                        className={`appearance-none input-form p-3 text-primary-black focus:outline-none 
                        focus:ring-none focus:border-primary focus:z-10 sm:text-sm`}/>

                    <div className='w-full'>
                        <label className='text-sm text-black-text mb-2 font-semibold'>Phone number*</label>
                        <PhoneInput
                            country={'ng'}
                            value={phone}
                            onChange={phone => setPhone(phone)}
                            placeholder="900 009 211"
                            inputProps={{
                            name: 'phone',
                            required: true
                            }}
                            containerClass={`w-full focus:border-primary`}
                            inputClass={`phone_input w-full `}/>
                        {!isphonevalid && (
                            <div className='flex items-center mt-2 text-xs'>
                                <div className='w-3 h-3 p-2 bg-primary-gray flex__center rounded-full text-white'>
                                    !
                                </div>
                                <p className='text-black-alt ml-1'>Phone number must have at least 13 digits</p>
                            </div>
                        )}
                        {console.log(isphonevalid)}
                        
                    </div>

                    <input
                        type="submit"
                        // disabled={formState.isValid ? false:true}
                        className={`w-full ${true ? "bg-primary-dark hover:bg-primary cursor-pointer"
                        :"bg-black-gray cursor-not-allowed" }
                         text-white py-3 rounded-xl mt-6`}
                        value="Continue"/>
                        
                    <p className=' text-sm text-center mt-4 text-primary-black-70'>Already have an account?
                        <Link className='text-primary font-medium' to="/login"> Log in</Link>
                    </p>

                    <Link to='/login' className='flex justify-center items-center mt-8 text-black-alt'>
                        <MdOutlineArrowBack/>
                        <p className='text-sm'>Back</p>
                    </Link>
                </form>

                
            </div>
        </div>
    )
}

export default Signup