import React from 'react'
import { useContext } from 'react'
import { DataContext } from '../../../context/DataContext'

//custom components


//sections
import FirstSection from './FirstSection'




function Business() {
  const data = useContext(DataContext)

  console.log(data)
  
  return (
    <div className='w-full min-h-screen bg-dashboard-bg'>

        <div className={`my-2 md:my-6 mb-8 md:mb-10 flex flex-col md:flex-row 
        gap-2 md:gap-0 justify-between items-start md:items-center`}>
          <div>
            <h1 className='text-xl md:text-2xl lg:text-3xl font-semibold text-black-tertiary'>
              Refund Request
            </h1>
          </div>
        </div>

        <FirstSection/> 
        
    </div>
  )
}

export default Business