import React, {useMemo, useEffect, useState, useContext} from 'react'

import {useNavigate} from 'react-router-dom'

// REACT HOOKS
import {useTable, usePagination} from 'react-table'
import {AuthContext} from '../../../context/auth-context';
import { useHttpClient} from '../../../hooks/less-http-hook'
import moment from 'moment';


// DATA
import {TICKETS_COLUMNS} from '../../../util/columns/columns';


//custom components
import Loader from '../loading/Loader';
import TablePagination from '../TablePagination/TablePagination';


// custom icons
import {RiDeleteBinLine} from 'react-icons/ri'

// custom css
import './tables.css'
import Pill from '../pills/Index';
import WarningModal from '../Modal/WarningModal';




function TicketTable(props) {    

    const auth = useContext(AuthContext)

    const history = useNavigate()

    const [eventModal, setEventModal] = useState(false)

    const [tableData, setTableData] = useState();

    const [cellData , setCellData] = useState([]);

    const [detailsData, setDetailsData] = useState({
        "current_page" : '1',
        "page_number":"15",
        "status":"0",
        "search_text":""
    })

    const [pages, setPages] = useState([]);

    // const [refresh, setRefresh] = useState(false);

    const {isLoading, error, sendRequest} = useHttpClient();

    useEffect(() => {
        setTableData(null)

        const formData = new FormData()
        formData.append('page_number',detailsData.page_number);
        formData.append('status',detailsData.status);


        const fetchData = async() => {
            
            try {
                const responseData = await 
                sendRequest(`${process.env.REACT_APP_API_CALL}/event/list-all?page_num=${detailsData.current_page}&page_size=${detailsData.page_number}`, 
                "GET", 
                null , 
                {Authorization: 'Bearer '+auth.token});

                setTableData(responseData.claim);
                setPages(responseData.claim.links)

                if(responseData){
                    setCellData([])
                    for(let file of responseData.claim.data){
                        setCellData((cell)=>[...cell, {
                            "id":file.id,
                            "date": moment(file.claims.created_at).format('l'),
                            "claim_name": file.claims.reward_name,
                            "details": file.claims.description,
                            "vendor_name": file.claims.vendor_name,
                            "claim_type":file.claims.type,
                            "vendor_link":file.claims.link,
                            "status":file.status
                        }])
                    }
                }



            } catch (err) {
                console.log(error)
            }
        };
        fetchData();

    }, [sendRequest, error, auth, detailsData]);





    const pageChangeHandler = (url) =>{
        if(url === null){
            return
        }

        let pageCustom = url.split("=")[1];

        setDetailsData({
            ...detailsData,
            "current_page":pageCustom.toString()
        })
    }

    const pageSizeChangeHandler = (number) => {
        setDetailsData({
            ...detailsData,
            "page_number":number.toString()
        })
    }

    const deleteTicketerHandler = () => {
        setEventModal(!eventModal)
    }
    // const onCancelEventHandler = () => {
    //     setEventModal(false)
    // }



    const columns = useMemo(() => TICKETS_COLUMNS, [])
    const data = useMemo(() => cellData, [cellData])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        // rows,
        // prepareRow
    } = useTable({
        columns,
        data,
        initialState: {
            pageIndex: 0
        }
    }, usePagination)

    return (
        <>
        <WarningModal show={eventModal} onCancel={deleteTicketerHandler} 
        title="Delete User" 
        text="Are you sure you want to delete this user account ?" 
        CancelText="Cancel"
        SubmitText="Yes"
        />

        {isLoading ? <Loader/> :
        
        <div className="bg-white pb-8 table__borderless mt-6 overflow-x-auto w-full">
             
            <table {...getTableProps()} className="w-full text-left">
                <thead className='border-b border-border-color w-full'>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} className="">
                            {headerGroup
                                .headers
                                .map(column => (
                                    <th {...column.getHeaderProps()} 
                                    className={`text-xs md:text-sm text-primary-black font-semibold capitialize 
                                    whitespace-nowrap p-4 px-6`}>
                                        {column.render('Header')}
                                    </th>
                                ))}
                            
                            <th className="text-xs md:text-sm text-primary-black-70 font-semibold whitespace-nowrap p-4"></th>
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {/* {rows.map((row, index) => {
                        prepareRow(row)

                        return (
                            <tr {...row.getRowProps()} className='box-b-1'>
                                <td className="text-sm py-4 whitespace-nowrap font-gilroy-medium">{index+1}</td>
                                {row
                                    .cells
                                    .map(cell => {
                                        
                                        return <td {...cell.getCellProps()} className="text-sm py-4 px-4 whitespace-nowrap font-gilroy-medium">{cell.render('Cell')}</td>
                                    })}
                                
                                <td className='text-sm py-4 font-gilroy-bold whitespace-nowrap'>
                                    <button className={`${row.original.status === '0' ? 'text-primary-purple' : ' text-gray-600'}`} 
                                    onClick={() => updateStatus(row.original.id)} disabled={row.original.status === '0' ? false : true}>
                                        {`${row.original.status === '0' ? 'Claim' : 'Claiming'}`} 
                                    </button>
                                </td>
                            </tr>
                        )
                    })} */}
                    <tr className='bg-odd-row font-sora'>
                    <td className="border-t-0 px-6  align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4 text-left ">
                        <p className='text-primary-grey'>GTbank Fashion Week</p>
                    </td>

                    <td className="px-6 align-middle  text-sm whitespace-nowrap p-4">
                        <p className='text-primary-grey'>#03999EED#</p>
                    </td>
                    

                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4">
                        <p className='text-primary-grey'>Okonkwo Chidima</p>
                    </td>

                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm truncate whitespace-nowrap p-4">
                        <p className='text-primary-grey'>oochdima@gmail.com</p>
                    </td>

                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm truncate whitespace-nowrap p-4">
                        <p className='text-primary-grey'>09012059519</p>
                    </td>

                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm truncate whitespace-nowrap p-4">
                        <p className='text-primary-grey'>22 Jan 2022</p>
                    </td>

                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm truncate whitespace-nowrap p-4">
                        <p className='text-primary-grey'>20</p>
                    </td>

                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4">
                        <RiDeleteBinLine className='text-primary-black text-lg cursor-pointer' onClick={deleteTicketerHandler}/>
                    </td>

              </tr>
                </tbody>

            </table>
            <div className='w-full dash-h-1 bg-primary-black opacity-50'></div>
            {tableData && (
                <TablePagination 
                current_page = {tableData.current_page}
                last_page = {tableData.last_page}
                prev_page_url = {tableData.prev_page_url}
                next_page_url = {tableData.next_page_url}
                pages = {pages}
                per_page = {tableData.per_page}
                first_page_url = {tableData.first_page_url}
                last_page_url={tableData.last_page_url}
                pageChangeHandler={pageChangeHandler}
                pageSizeChangeHandler={pageSizeChangeHandler}/>
           )}

            
            
        </div>
        }
        </>              
    )
}

export default TicketTable