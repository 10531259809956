import React, { useState, useCallback, useEffect } from 'react';

import { useDropzone } from 'react-dropzone';
import {IoCloseCircle} from 'react-icons/io5'
import { images } from '../../../../assets';


const ImageUploader = ({ imageProp, onUpload }) => {
  const [imagesLoaded, setImagesLoaded] = useState([...imageProp]);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() =>{
    setImagesLoaded([...imageProp])
  }, [imageProp])

  // Handler for when files are dropped or selected
  const onDrop = useCallback((acceptedFiles) => {
    const imagesDrop = acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file), // Create a preview URL for displaying the image
      })
    );
    
    
    setImagesLoaded((prevImages) => {
        let totalImages = [...prevImages, ...imagesDrop]

        onUpload(totalImages);
        return totalImages
    })
    
  }, [onUpload]);

  // Accept only image files
  const acceptFileTypes = 'image/*';

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptFileTypes,
    multiple: true,
    onDragEnter: () => setIsHovered(true),
    onDragLeave: () => setIsHovered(false),
  });

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const deleteImageHandler = (index) => {
    setImagesLoaded((prevImages) => {
        let prevArray = [...prevImages]
        prevArray.splice(index, 1);

        onUpload(prevArray);
        return prevArray
    });
  }

  return (
    <div className="px-6 py-3 drop-border  rounded flex flex-wrap gap-8 justify-center lg:justify-start items-center w-full">

        {imagesLoaded.length !==0 && imagesLoaded.map((imageIdx, index) => {
         
          if (imageIdx === undefined){
            return
          }
          return (
            <div className="relative w-fit">
                <img
                key={index}
                src={typeof imageIdx === 'string' ? imageIdx : imageIdx instanceof File ? URL.createObjectURL(imageIdx) :''}
                alt={`Uploaded ${index}`}
                style={{ width: '100px', height: '100px', margin: '5px' }}
                />
                <IoCloseCircle 
                className="text-lg absolute border rounded-full opacity-80 border-white top-2 right-2 text-primary" 
                onClick={() => deleteImageHandler(index)}/>
            </div>
        )
        })}
        {imagesLoaded.length === 0 && 
        <div className={`dropzone p-4 w-72 `} {...getRootProps()} 
        onMouseLeave={handleMouseLeave}
        >
            <input {...getInputProps()} />
            {isHovered && <p className='text-sm py-8 text-primary-black text-center font-medium'>Drop your image</p>}
            {!isHovered && (
                <>
                    <div className={`text-sm  text-primary-black text-center`}>
                        <img src={images.UploadCloud.src} alt={images.UploadCloud.alt} className=' mx-auto mb-2'/>
                        <p className='font-medium'><span className='text-primary'>Click to upload </span>or drag and drop</p>
                        <p className=''>SVG, PNG, JPG or (max. 800x400px)</p>
                    </div>
                </>
            )}
        </div>
        }
      
    </div>
  );
};

export default ImageUploader;
