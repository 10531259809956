import React, { useEffect, useState, useContext, useMemo } from 'react'

import Board from "./Board"

import "./overview.css"
import EventList from './EventList'
import { useApi } from '../../../api/api-hook'
import { DataContext } from '../../../context/DataContext'
import { businessEndpoints } from '../../../api'
import Loader from '../../Reusables/loading/Loader'

function Index() {

  let test = useContext(DataContext)
  console.log(test)
  let data = null
  if(localStorage.getItem('profileData')){
    data = JSON.parse(localStorage.getItem('profileData'))
    
  }

  const { getData, error, isLoading} = useApi()

  const fetchData = useMemo(() => async () => {
    const response = await getData(businessEndpoints['ORGANISERS-DETAILS'], null,`?organiser_id=${data?.organiser?.id}`);
    if(response){
      console.log(response);
    }
  }, [getData, data]);

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <> 
        {isLoading ? <Loader/> : 
        <>
          <Board/>
          <EventList/>
        </>}
    </>
  )
}

export default Index