import React from 'react'

function BoardCard({title, amount, color, icon, currency}) {
  return (
    <div data-aos="fade-up" className="p-4 bg-white px-6 card-shadow w-full">
            <div className="flex justify-between items-center flex-wrap gap-4">
                <p className="text-black-alt uppercase">{title}</p>
                <div className={`flex justify-center items-center p-3 bg-opacity-10 rounded-full ${color}`}>
                    {icon}
                </div>
            </div>

            <h1 className='text-2xl md:text-3xl lg:text-4xl font-medium text-primary-black mt-4'>{currency ? "₦": ""}{amount}</h1>
      </div>
  )
}

export default BoardCard