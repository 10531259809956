import React, { useContext, useEffect, useState } from 'react'

import BoardCard from "./BoardCard"
import {IoTicket} from 'react-icons/io5'
import { DataContext } from '../../../context/DataContext'
import { useApi } from '../../../api/api-hook'
import { businessEndpoints } from '../../../api'
import { number_format } from '../../../util/functions'

function Board() {
  let data = null
  if(localStorage.getItem('profileData')){
    data = JSON.parse(localStorage.getItem('profileData'))
  }

  const [boardDetails, setBoardDetails] = useState({
    revenue:0,
    ticket:0,
    transactions:0,
    events:0
  })

  const { getData, error, isLoading} = useApi()

  // let data = null
  // if(localStorage.getItem('profileData')){
  //   data = JSON.parse(localStorage.getItem('profileData')).organiser
    
  // }

  useEffect(()=>{

    const fetchData = async () =>{
      const [countRevenue, countTicket, countTransactions, countEvents] = await Promise.all([
        getData(businessEndpoints['COUNT-REVENUE'], null, `?organiser_id=${data?.organiser?.id}`),
        getData(businessEndpoints['COUNT-TICKET'], null, `?organiser_id=${data?.organiser?.id}`),
        getData(businessEndpoints['COUNT-TRANSACTIONS'], null, `?organiser_id=${data?.organiser?.id}`),
        getData(businessEndpoints['COUNT-EVENTS'], null, `?organiser_id=${data?.organiser?.id}`)
      ])

      if(countRevenue){
        setBoardDetails(prevData => {
          return{
            ...prevData,
            revenue:number_format(countRevenue?.data?.revenue || 0)
          }
        })
      }
      if(countTransactions){
        setBoardDetails(prevData => {
          return{
            ...prevData,
            transactions:number_format(countTransactions?.data?.transaction_count || 0)
          }
        })
      }
      if(countEvents){
        setBoardDetails(prevData => {
          return{
            ...prevData,
            events:number_format(countEvents?.data?.events_count || 0)
          }
        })
      }

      if(countTicket){
        setBoardDetails(prevData => {
          return{
            ...prevData,
            ticket:number_format(countTicket?.data?.ticket_count || 0)
          }
        })
      }
    }

    fetchData()
  },[])

  
  return (
    <div className=" py-10 px-12 ">
        <h3 className='font-semibold mb-6'>
          Welcome back {`, ${data?.organiser?.first_name || ""} ${data?.organiser?.last_name || ""}` || ""}
        </h3>

        <div className='grid grid-cols-1 place-items-center md:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-6 w-full'>
          <BoardCard title="All Event"
              amount={boardDetails.events || "0"}
              color="bg-primary-green"
              icon={(<IoTicket className='text-primary-green'/>)}/>
          
          <BoardCard title="Transactions"
              amount={boardDetails.transactions || "0"}
              color="bg-primary-yellow"
              icon={(<IoTicket className='text-primary-yellow'/>)}/>
          
          <BoardCard title="Ticket Issued"
              amount={boardDetails.ticket || "0"}
              color="bg-primary-blue"
              icon={(<IoTicket className='text-primary-blue'/>)}/>
          
          <BoardCard title="Revenue"
              amount={boardDetails.revenue || "0"}
              color="bg-primary-purple"
              currency
              icon={(<IoTicket className='text-primary-purple'/>)}/>
        </div>
    </div>
  )
}

export default Board