import React, {useState, useContext} from 'react'
import { DataContext } from '../../../context/DataContext'

//custom components

//sections


//custom icons
import {IoChevronBack} from 'react-icons/io5'
import CardHoc from '../../Reusables/Cards/CardHoc'
import NotificationModal from '../../Reusables/Modal/NotificationModal'

import { Link } from 'react-router-dom'
import WarningModal from '../../Reusables/Modal/WarningModal'
import images from '../../../assets'
import "./FundRequest.css"




function PaymentDetails() {
  const data = useContext(DataContext)

  
  const [typeNotification, setTypeNotification] = useState("")

  console.log(data)
  
  const handleNotification = (type) => {
    setTypeNotification(type)
  }
  return (
    <>
        <NotificationModal show={typeNotification === "approve"} 
        onCancel={() => handleNotification("")} 
        buttonClass="w-full bg-primary-blue text-white" 
        CancelText="OK" textWhite
        title={"Successful"}
        text={`The user account has been suspended, the user can no longer log in`}/>

        <WarningModal 
        show={typeNotification === "disapprove"} 
        onCancel={() => handleNotification("")} 
        onSubmitButton={() => handleNotification("")}
        title={"Suspend User"} CancelText="Cancel" SubmitText="Yes"
        text={`Are you sure you want to suspend the user account ?`}/>
    
    <div className='w-full min-h-screen bg-dashboard-bg'>

        

        <div className={`my-2 md:my-6 mb-8 md:mb-10 flex flex-col
        gap-2 items-start`}>
            <Link  to="/fund-request">
                <IoChevronBack/>
            </Link>
          <div>
            <h1 className='text-xl md:text-xl lg:text-2xl font-medium text-primary-grey'>
            Request details
            </h1>
           
          </div>
        </div>


        <CardHoc className="grid grid-cols-1 w-full bg-white xl:w-4/5">
            {/* Seller's Details First Section */}
            <div classname="">
                <h3 className="text-primary-black">Sender details</h3>
            </div>

            <div className='flex justify-between items-center gap-4  flex-wrap my-6 mb-4'>
                <div className='flex items-center flex-wrap'>
                    <img src={images.profileImg.src} alt={images.profileImg.alt} 
                    className='w-12 h-12 rounded-full mr-4'/>
                    <div className=''>
                        <h3 className='text-base font-semibold text-primary-black'>Allen Bala</h3>
                        <p className='text-base text-black-alt'>allensmyllen@gmail.com</p>
                    </div>
                </div>

                <div className='flex items-center lg:mr-8'>
                    <div className='fund-border p-2 mr-3 flex flex-wrap items-center gap-4'>
                        <p>UBA</p>
                        <p>Allen Evwidonor</p>
                        <p>2083400230</p>
                    </div>

                    <img src={images.copy.src} alt={images.copy.alt}/>
                </div>
            </div>

            {/* Seller's Details Second Section */}
            <div className=' mt-4'>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-14 font-inter mb-4">
                    <div>
                        <p className='text-sm payments-title-color font-semibold'>Event Name</p>
                        <p className='text-sm payments-color break-words'>GTBank Fashion Week</p>
                    </div>
                    <div>
                        <p className='text-sm payments-title-color font-semibold'>Event Date</p>
                        <p className='text-sm payments-color break-words'>June 13 2023</p>
                    </div>

                    <div>
                        <p className='text-sm payments-title-color font-semibold'>Request Date</p>
                        <p className='text-sm payments-color break-words'>June 17 2023</p>
                    </div>
                    <div>
                        <p className='text-sm payments-title-color font-semibold'>Ticket Purchase</p>
                        <p className='text-sm payments-color break-words'>Early Bird</p>
                    </div>
                
                    <div>
                        <p className='text-sm payments-title-color font-semibold'>Ticket Amount</p>
                        <p className='text-sm payments-color break-words'>NGN 25,000</p>
                    </div>
                    <div>
                        <p className='text-sm payments-title-color font-semibold'>Request Description</p>
                        <p className='text-sm payments-color break-words'>I am not going to be able to attend the event</p>
                    </div>
                
                </div> 

            </div>

            
        </CardHoc>

        <div className=' my-6 mb-8 flex justify-end items-center gap-4 w-full xl:w-4/5'>
            <button onClick={() => handleNotification("approve")} 
            className="bg-primary-green font-light text-white px-12 py-3 w-fit rounded-lg">
                Approve
            </button>
            <button onClick={() => handleNotification("disapprove")} 
            className="bg-primary-red font-light text-white px-12 py-3 w-fit rounded-lg">
                Disapprove
            </button>
        </div>
        
    </div>
    </>
  )
}

export default PaymentDetails