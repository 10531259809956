import React, {useState} from 'react'
import {RiSearchLine} from 'react-icons/ri'

import ReactDatePicker from 'react-datepicker';

import { BsCalendar4 } from 'react-icons/bs';
import Button from '../../Reusables/Button/Button';
import AddTicketerModal from '../../Reusables/Modal/AddTicketerModal';
import TicketTable from '../../Reusables/Tables/TicketTable';

function FirstSection() {
    const [filterDate, setFilterDate] = useState('');
    const [showModal, setShowModal] = useState('')
    
      const handleSubmit = (event) => {
        event.preventDefault();
        
      };

    const ModalCancelHandler = () =>{
        setShowModal('')
    }

    return(

        <>
        {/* Table */}<AddTicketerModal show={showModal==="payment"} onCancel={ModalCancelHandler}/>
        <div className='bg-white table__border pt-8'>
        <div className="flex flex-col md:flex-row items-start md:items-center justify-end px-6">
           

            <div className='flex items-center justify-end w-full'>
                <div className={`event-input text-black flex justify-end items-center w-56 mr-4`}>
                    <ReactDatePicker
                    selected={filterDate}
                    onChange={(date) => setFilterDate(date)}
                    placeholderText="Filter by date"
                    className="!w-full focus:outline-none italic"
                    />
                    <BsCalendar4/>
                </div>

                <Button textWhite className={`bg-primary-dark`} onClick={() => setShowModal("payment")}>+ Add new</Button>
            </div>

        </div>

        <TicketTable/>

        </div>
        </>
    )
}

export default FirstSection