import React from 'react'
import Events from '../../components/DashboardFragments/Events/Index.js'

function Index() {
  return (
    <section className=' xl:pb-[32rem] pt-[8.5rem] pb-5 px-6 md:px-12 lg:px-24'>
        <Events/>
    </section>
  )
}

export default Index