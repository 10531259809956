import "./Footer.css";
import * as Icons from 'react-icons/fa';
import { footerItems } from '../../../constants';
// import images from "../../../assets";
import { TickitIcon } from "../SvgIcons";
import { Link } from "react-router-dom";

const Footer1 = () => {
    const currentYear = new Date().getFullYear();
    const onSubscribeEmail = (e) => {
        e.preventDefault();
    };
    return (
        <footer className="bg-white pt-6">
            <div className='flex justify-between items-center flex-col lg:flex-row py-8 md:py-12 px-6 md:px-12 lg:px-12 xl:px-24 lg:pt-20'>
                {/* logo section */}
                <div className='flex flex-col items-center lg:items-start gap-6 mb-8'>
                    <Link to="/" className="pl-2 hover:scale-105 transition-[.3] cursor-pointer">
                        <TickitIcon />
                    </Link>

                    <ul className='grid grid-cols-2 place-items-center lg:flex md:flex-row md:gap-6 mx-3 gap-2 transition'>
                        {footerItems.map((item) => {
                            if (item.title === 'Contact Us') {
                                return (
                                    <li key={item.id} className={`${item.nName} border-b border-transparent hover:border-primary text-text-color-400`}>
                                        <a
                                            href="mailto:hello@vivacity.io?subject=Making Inquiries at Vivacity&body=Hi Vivacity"
                                            target="_blank" rel="noopener noreferrer"
                                            className={`${item.nName} text-base`}>
                                            {item.title}
                                        </a>
                                    </li>
                                );
                            }
                            return (
                                <li key={item.id} className={`${item.nName} border-b border-transparent hover:border-primary text-text-color-400`}>
                                    <a href={item.path} className={`${item.nName} text-white text-base`}>{item.title}</a>
                                </li>
                            );
                        })
                        }
                    </ul>
                </div>

                {/* Subscribe section */}
                <form onSubmit={onSubscribeEmail} className="flex lg:w-1/3 justify-center items-center flex-col mt-8 lg:mt-0 lg:block">
                    <label className='text-base text-text-color-400 font-semibold mb-2 lg:mb-0' htmlFor='subscribe_email'>Stay up to date</label>
                    <div className='grid grid-cols-3 sm:flex flex-wrap gap-2 items-center mt-2 md:mt-4'>
                        <input
                            type="email"
                            id='subscribe_email'
                            name='subscribe_email'
                            placeholder='Enter your email'
                            className='text-primary-black col-span-2 text-sm mr-2 p-3 py-2 border rounded-sm outline-none lg:flex-1' />
                        <input
                            type="submit"
                            id='submit_button'
                            value="Subscribe"
                            className='bg-primary text-base text-white px-2 sm:px-4 py-2 rounded-sm'
                        />
                    </div>
                </form>
            </div>
            <hr />

            <div className='flex flex-col justify-between items-center lg:flex-row py-6 px-6 md:px-12 md:py-4 md:gap-2 xl:px-24 lg:pb-6'>
                <p className='text-text-color-400 text-xs md:text-base mb-4 md:mb-0 text-center sm:text-start'>© {currentYear} Tickit Ticketing Company. All Rights reserved.</p>

                <div className='flex items-center'>
                    <Icons.FaTwitter className='text-text-color-400 hover:text-primary-blue mr-4 cursor-pointer hover:-translate-y-1 transition' />
                    <Icons.FaFacebookF className='text-text-color-400 hover:text-primary-blue mr-4 cursor-pointer hover:-translate-y-1 transition' />
                    <Icons.FaInstagram className='text-text-color-400 hover:text-primary-blue cursor-pointer hover:-translate-y-1 transition' />
                </div>
            </div>
        </footer>
    );
};

export default Footer1;