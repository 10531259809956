import React, { useContext } from 'react'
import { images } from '../../../assets'
import Input from '../../../UI_Elements/Input/Input'

// custom util function
import {VALIDATOR_EMAIL, VALIDATOR_MINLENGTH, VALIDATOR_REQUIRE} from '../../../util/validators'

// custom hooks
import {useForm} from '../../../hooks/form-hook'
import { useState } from 'react'

//custom components
import PhoneInput from 'react-phone-input-2'
import ReactSelect from '../../Reusables/select/ReactSelect'
import Button from '../../../UI_Elements/Button/Button';
import PhotoUpload from './PhotoUpload'
import { DataContext } from '../../../context/DataContext'
import NaijaStates from 'naija-state-local-government';
import { useApi } from '../../../api/api-hook'
import { businessEndpoints } from '../../../api'
import Loader from '../../Reusables/loading/Loader'
import { toast } from 'react-toastify'

function Profile() {
    const [gender, setGender] = useState(null)
    const [openPhotoUpload, setOpenPhotoUpload] = useState(false)

    const data = useContext(DataContext).userData

    const dataContext = useContext(DataContext)

    const naijaStates = NaijaStates.states()

    const naijaStatesObject = naijaStates.map(item => {
        return {
          label: item,
          value: item.toLowerCase()
        };
      })

    const statusOptions = [
        {
          label:"Male", 
          value:"male"
        },
        {
          label:"Female", 
          value:"female"
        }
      ]

    const [defaultStatus, setDefaultStatus] = useState([{
        label:"Male", 
        value:"male"
    }])

    const [states, setStates] = useState((prevData) =>{
        return{
            ...prevData,
            label:data?.city || "", 
            value:data?.city || ""
        }
    })

    const [phone,
        setPhone] = useState(data?.phone_number || "000 0000 000")
    const [isphonevalid, setisphonevalid] = useState(true)

    const [formState,
        inputHandler] = useForm({

        email: {
            value: '',
            isValid: false
        },
        firstname: {
            value: '',
            isValid: false
        },
        lastname: {
            value: '',
            isValid: false
        },
        business_name: {
            value: '',
            isValid: false
        },
        business_email_address :  {
            value: '',
            isValid: false
        },
        address: {
            value: '',
            isValid: false
        },
    }, false);


    const { patchData, error, isLoading} = useApi()

    const submitHandler = async event => {
        event.preventDefault();

        try {

            const formData = new FormData()

            formData.append("organiser_id", data?.id)
            formData.append('email_address', formState.inputs.email.value)
            formData.append('business_name', formState.inputs.business_name.value)
            formData.append('business_email_address', formState.inputs.business_email_address.value)
            formData.append('first_name', formState.inputs.firstname.value)
            formData.append('last_name', formState.inputs.lastname.value)
            formData.append('phone_number', phone)
            formData.append('city', states?.value || "")
            formData.append('gender', gender?.value || "")
            formData.append('address', formState.inputs.address.value || "")
            
            
            const response = await patchData(businessEndpoints['UPDATE-ORGANISER'], formData, null)

            // console.log(response)
            if(response){
                console.log("I am here")
                localStorage.setItem('profileData', JSON.stringify(response.data))
                dataContext.updateData(response.data?.organiser)
                toast.success(response.message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    // icon: ({theme, type}) =>  <img src={images.successIcon[0]} alt={images.successIcon[1]} className="w-14 h-8"/>
                    });
                
            }
            
        } catch (err) {
            
            console.log(err)
        }
        
    };

    const openPhotoUploadHandler = () =>{
        setOpenPhotoUpload(!openPhotoUpload)
    }


  return (
    <>
    {isLoading && <Loader/>}
    {/* <PhotoUpload show={openPhotoUpload} onCancel={openPhotoUploadHandler}/> */}
    <div className='px-2 md:px-4 xl:px-8 py-4 flex flex-col md:flex-row items-center md:items-start gap-6 md:gap-x-8 lg:gap-x-16 w-full'>
        <div className='flex flex-col items-center justify-center'>
            {/* <h3>Profile</h3> */}
            
            <img 
            src={images.logo["src"]} 
            alt={images.logo["alt"]} 
            className="w-24 h-24 my-2"/>
            {/* <label htmlFor="upload-photo"  className=' px-6 py-2 rounded text-white text-sm'
            style={{background:"#311136"}}>
                Update
            </label>
            <input type="file" className='h-0 w-0' id='upload-photo'/> */}
        </div>

        <form 
        onSubmit={e => submitHandler(e)}
        className={`mt-4 grid grid-cols-1 md:grid-cols-2  
        gap-4 lg:gap-x-8 items-center place-items-start w-full`}>
        <Input
            type="text"
            placeholder='Enter your first name'
            label="First Name"
            labelClassName={`text-sm mb-2 font-semibold form_login_label`}
            id="firstname"
            initialValue={`${data?.first_name || ''}`}
            initialValid={true}
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Please enter a valid first name"
            onInput={inputHandler}
            divClassName={`mb-3 `}
            containerClassName={`relative  `}
            className={`appearance-none ${formState.inputs.firstname.value && "bg-light-purple"}  
            input-form p-3 text-primary-black-70 focus:outline-none 
            focus:ring-none focus:border-primary focus:z-10 sm:text-sm`}/>

        <Input
            type="text"
            placeholder='Enter your last name'
            label="Last Name"
            labelClassName={`text-sm mb-2 font-semibold form_login_label`}
            id="lastname"
            initialValue={`${data?.last_name || ''}`}
            initialValid={true}
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Please enter a valid last name"
            onInput={inputHandler}
            divClassName={`mb-3 `}
            containerClassName={`relative  `}
            className={`appearance-none ${formState.inputs.lastname.value && "bg-light-purple"}  
            input-form p-3 text-primary-black-70 focus:outline-none 
            focus:ring-none focus:border-primary focus:z-10 sm:text-sm`}/>
        <Input
            type="text"
            placeholder='Enter your business name'
            label="Business Name"
            labelClassName={`text-sm mb-2 font-semibold form_login_label`}
            id="business_name"
            initialValue={`${data?.business_name || ''}`}
            initialValid={true}
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Please enter a valid business name"
            onInput={inputHandler}
            divClassName={`mb-3 `}
            containerClassName={`relative  `}
            className={`appearance-none ${formState.inputs.lastname.value && "bg-light-purple"}  
            input-form p-3 text-primary-black-70 focus:outline-none 
            focus:ring-none focus:border-primary focus:z-10 sm:text-sm`}/>

        <Input
            type="email"
            placeholder='Enter your business email address'
            label="Business Email Address"
            labelClassName={`text-sm mb-2 font-semibold form_login_label`}
            id="business_email_address"
            initialValue={`${data?.business_email_address || ''}`}
            initialValid={true}
            validators={[VALIDATOR_EMAIL()]}
            errorText="Please enter a valid business email"
            onInput={inputHandler}
            divClassName={`mb-3 `}  
            containerClassName={`relative`}
            className={`appearance-none 
            input-form input-fade p-3 text-primary-black-70 focus:outline-none 
            focus:ring-none focus:border-primary focus:z-10 sm:text-sm`}/>

        <Input
            type="email"
            placeholder='Enter your email address'
            label="Email Address"
            labelClassName={`text-sm mb-2 font-semibold form_login_label`}
            id="email"
            initialValue={`${data?.email_address || ''}`}
            initialValid={true}
            validators={[VALIDATOR_EMAIL()]}
            errorText="Please enter a valid email"
            onInput={inputHandler}
            divClassName={`mb-3 `}
            readOnly
            containerClassName={`relative`}
            className={`appearance-none 
            input-form input-fade p-3 text-primary-black-70 focus:outline-none 
            focus:ring-none focus:border-primary focus:z-10 sm:text-sm`}/>
        
        <div className='w-full mb-6 md:mb-3'>
            <label className='text-sm mb-2 -mt-1 font-semibold'>Phone Number</label>
            
            <PhoneInput 
                country={'ng'}
                value={phone}
                onChange={phone => setPhone(phone)}
                placeholder="900 009 211"
                // isValid={(value) => {
                //     if (value.match(/234/) && value.length >= 13) {
                //     setisphonevalid(true)
                //     return true
                //     } else {
                //         setisphonevalid(false)
                //         return false
                //     }
                // }}
                inputProps={{
                name: 'phone',
                required: true
                }}
                containerClass={`w-full mt-1`}
                inputClass={`phone_input w-full`}/>
            {/* {(
                <div className={`flex items-center mt-1 ${isphonevalid ? "visible" : "invisible"} text-xs`}>
                    <div className='w-3 h-3 p-2 bg-primary-gray flex__center rounded-full text-white'>
                        !
                    </div>
                    <p className='text-primary-gray ml-1'>Please start with +234 & have at least 13 digits</p>
                </div>
            )} */}
            
        </div>

        

        <div className='w-full mb-6' >
            <p className='text-sm 6b-2 font-semibold form_login_label'>Gender</p>
            <ReactSelect 
            options={statusOptions} 
            className="appearance-none padding-3 input-form sm:text-sm focus:z-10"
            input={(data) => {
                setGender(data)
            }} 
            value={defaultStatus[0]}
            placeholder="Gender"/>
        </div>

        <div className='w-full mb-6' >
            <p className='text-sm mb-2 font-semibold form_login_label'>City</p>
            <ReactSelect 
            options={naijaStatesObject} 
            className="appearance-none padding-3 input-form sm:text-sm focus:z-10 "
            input={(data) => {
                // console.log(data)
                setStates(prevData => {
                    return{
                        ...prevData,
                        ...data
                    }
                })
            }} 
            value={states}
            placeholder="State of residence"/>
        </div>

        
        <div className='md:col-span-2 w-full'>
            <Input
                type="text"
                placeholder='Enter a valid business address'
                label="Business Address"
                labelClassName={`text-sm mb-2 font-semibold form_login_label`}
                id="address"
                validators={[VALIDATOR_REQUIRE(), VALIDATOR_MINLENGTH(5)]}
                errorText="Please enter a valid address"
                onInput={inputHandler}
                divClassName={`mb-3 `}
                containerClassName={`relative  `}
                className={`appearance-none ${formState.inputs.email.value && "bg-light-purple"}  
                input-form p-3 text-primary-black focus:outline-none 
                focus:ring-none focus:border-primary focus:z-10 sm:text-sm`}/>
        </div>
        
        <div className='w-full md:col-span-2 mt-6 flex justify-end'>
          <Button type="submit" bgPrimary textWhite shadow  className={`ml-auto !px-12 !bg-primary-dark hover:!bg-primary `}>
            {isLoading ? "Saving ..." : "Save Changes"}
          </Button>
        </div>
        </form>

        
    </div>
    </>
  )
}

export default Profile