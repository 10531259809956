import React, { useState } from 'react'
import BreadCrumbNavigation from '../BreadCrumbNavigation'
import images from '../../../assets'

import "./ticket.css"
import { useNavigate } from 'react-router-dom'
import RefundModal from './components/RefundModal'

function OrdersDetail() {

    const history = useNavigate()

    const [modal, setmodal] = useState(false)

    const showModalHandler = () =>{
        setmodal(!modal)
    }
  return (
    <>
        <RefundModal show={modal} onCancel={showModalHandler} closeButton/>
        <BreadCrumbNavigation path="/transactions" nav="Transactions"/>
        
        
        <div className='grid grid-cols-1 lg:grid-cols-5 gap-6 lg:gap-8 place-content-center items-center justify-center'>
            <div className='bg-white ticket-bg p-6 md:px-8 lg:col-span-3 lg:col-start-2'>
                <div className='flex justify-center items-center flex-col'>
                    <h2 className='text-black-text font-semibold text-xl mb-4'>Ticket Receipt</h2>
                    <img src={images.ticketCode["src"]} alt={images.ticketCode["alt"]} className='w-32 lg:w-40'/>
                </div>

                <div className=' my-12 md:my-16 border-t-2 border-dashed border-primary-dark relative'>
                    <div className={`border-2 border-primary-dark text-primary-dark px-6 py-2 
                    w-fit object__center bg-white rounded`}>
                        <p>Ticket 1 of 1</p>
                    </div>
                </div>

                <div className='mb-8'>
                    <p className='font-light text-black-text mb-4'>Name</p>
                    <p className='font-bold text-black-text text-2xl'>Allen<br/>Evwidonor</p>
                </div>
                <div className='mb-8'>
                    <p className='font-light text-black-text mb-3'>Ticket/ seat</p>
                    <p className='font-semibold text-black-text text-base'>Table for 4</p>
                </div>
                <div className='mb-8'>
                    <p className='font-light text-black-text mb-3'>Event</p>
                    <p className='font-semibold text-black-text text-base lg:w-3/5'>{`Call for Designers & Exhibitors - 
                    Fashions Finest Africa EPIC SHOW`}</p>
                </div>

                <div className="flex justify-between items-center mb-8">
                    <div className=''>
                        <p className='font-light text-black-text mb-3'>Date</p>
                        <p className='font-semibold text-black-text text-base mb-2'>Sat, Jun 10 <br/>Start at 2:00 PM</p>
                        <p className='font-semibold text-primary text-base flex items-center gap-2'><img 
                        src={images.calendar["src"]} alt={images.calendar["alt"]}/> Add to Calendar</p>
                    </div>

                    <div className=''>
                        <p className='font-light text-black-text mb-3'>Location</p>
                        <p className='font-semibold text-black-text text-base mb-2'>Ikoyi, Lagos, LA</p>
                        <p className='font-semibold text-primary text-base flex items-center gap-2'><img 
                        src={images.map["src"]} alt={images.map["alt"]}/> See Map</p>
                    </div>
                </div>

                <div className='mb-8'>
                    <p className='font-light text-black-text mb-3'>Order number</p>
                    <p className='font-semibold text-black-text text-base '>L6602794039</p>
                </div>

                <div className="flex justify-between items-center mt-16">
                    <div className=''>
                        
                        <p className='font-semibold text-black-text text-2xl mb-2'>TOTAL</p>
                        
                    </div>

                    <div className=''>
                        <p className='font-semibold text-primary text-4xl'>₦6.200</p>
                    </div>
                </div>

            </div>

        </div>
    </>
  )
}

export default OrdersDetail