import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

// imported react hooks and packages
import { CSSTransition } from 'react-transition-group';

// custom components
import Backdrop from '../Backdrop/Backdrop';
import Button from '../Button/Button.jsx';

// custom hooks
// import {useHttpClient} from '../../../../hooks/less-http-hook';
// import {AuthContext} from '../../../../context/auth-context';

// custom icons
import { VscChromeClose } from 'react-icons/vsc';

// custom css
import './Modal.css';
import { images } from '../../../assets'

import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  amount: Yup.number().required("amount is required"),
});

const PaymentModalOverlay = props => {
    

    // const    auth = useContext(AuthContext)
    // const { error, sendRequest} = useHttpClient();
    const initialValues = {
        bankName: "",
        amount: "",
        accountName: "",
        accountNumber: "",
        accountOptions:"",
      };

  useEffect(() => {
    return
  }, [props.refresh])


//   const handleSwitch = (type) =>{
//     setToggle((prevState) => {
//         const updatedToggle = {};
    
//         for (const key in prevState) {
//           if(prevState[key] === true && key === type){
//             updatedToggle[key] = false
//           }
//           else{
//             updatedToggle[key] = key === type
//           }
//         }
    
//         return updatedToggle;
//       });
//   }

  const handleSubmit = async(values, { setSubmitting }) => {
    console.log(values)
    console.log(setSubmitting)
    props.onSubmit()
    

    // try {

    //     const data = JSON.stringify({
    //         firstname: values?.firstname,
    //         lastname: values?.lastname,
    //         email: values?.email,
    //         phone_number: values?.phone,
    //     })

    //     const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}admin/create`, 'POST', data, {Authorization: 'Bearer '+auth.token, 'Content-Type': 'application/json'});

    //     if(responseData){
          
    //         props.onSubmit()
    //     }
        

    // } catch (err) {
        
    //     console.log(error)
    // }

    props.onSubmit()

  };

  const content = (
    <div className={`dashboard-modal dashboard-modal-80 px-2 md:px-4
    ${props.loading && "dashboard-modal-loading"} 
    ${props.className} ${props.modalTopRight && 'dashboard-modal-top-right'}`}  
    style={props.style}>
      {/* <header className={`dashboard-modal__header ${props.headerClass}`}>
        <h2>{props.header}</h2>
      </header> */}
    <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
            <div className={`dashboard-modal__content ${props.contentClass}`}>
                <div className="flex items-start flex-col">
                    <img src={images.bankIcon.src} alt={images.bankIcon.alt} className='my-4'/>
                    <h4 className='mb-2 text-black-text font-medium'>Select your withdrawal account</h4>
                    <p className='mb-4 text-primary-grey text-sm opacity-75'>Money will be sent to your withdrawal account</p>

                    

                    <div className='w-full '>
                        <div>
                            <label htmlFor='accountOptions' className='text-main-black font-medium mb-2'>Select account</label>
                            <Field className="form__input font-sora w-full py-2 px-2" as="select" id="accountOptions" 
                            name="accountOptions" placeholder="Select Account">
                                <option value="allen Edoja">Select option</option>
                                <option value="allen Edoja">Allen Edoja</option>
                            </Field>
                            <ErrorMessage className="text-xs" name="accountOptions" component="div" />
                        </div>

                        <div className='my-4 flex flex-col gap-1 p-2 box-card'>
                            <p className='text-sm text-black-alt'>UBA</p>
                            <p className='text-sm text-black-alt my-3'>Allen Evwidonor</p>
                            <p className='text-sm text-black-alt'>2083400230</p>
                        </div>

                        <div className='my-4'>
                        <label htmlFor='amount' className='text-main-black font-medium mb-2'>Amount</label>
                            <Field className="form__input font-sora w-full py-2 px-2" type="number" id="amount" 
                            name="amount" placeholder="Enter Amount"/>
                            <ErrorMessage className="text-xs" name="amount" component="div" />
                        </div>

                        
                    </div>

                    
                </div> 
            {props.children}
            </div>
            <footer className={`dashboard-modal__footer w-full flex justify-center items-center ${props.footerClass}`}>
            <Button border shadow className={`cancel-box w-full ${props.cancelClassName}`} onClick={props.onCancel}>Cancel</Button>
            <Button bgPrimary textWhite className={`w-full ml-3 bg-primary-dark ${props.submitClassName}`}  
            disabled={isSubmitting} type="submit">Continue</Button>
            </footer>
        </form>
    
    )}
      </Formik>

      {/* close button */}
      <div className={`absolute top-4 right-4 ${props.closeButton ? 'block':'hidden'}`}>
          <button onClick={props.onCancel}>
              <VscChromeClose
                  className='text-base text-dashboard-black rounded pointer'/>
          </button>
      </div>
    </div>
  );
  return ReactDOM.createPortal(content, document.getElementById('modal-hook'));
};

const PaymentModal = props => {
  return (
    <>
      {props.show && <Backdrop onClick={props.onCancel} fade={true}/>}
      <CSSTransition
        in={props.show}
        mountOnEnter
        unmountOnExit
        timeout={200}
        classNames="dashboard-modal"
      >
        <PaymentModalOverlay {...props} />
      </CSSTransition>
    </>
  );
};

export default PaymentModal;
