import React, { useContext } from 'react'

// custom components
import Input from '../UI_Elements/Input/Input'

//custom icons
import {AiOutlineLoading3Quarters} from 'react-icons/ai'

// custom util function
import {VALIDATOR_EMAIL, VALIDATOR_REQUIRE} from '../util/validators'

// custom hooks
import {useForm} from '../hooks/form-hook'
import {useHttpClient} from '../hooks/http-hook';
import {AuthContext} from '../context/auth-context';

import { Link, useNavigate } from 'react-router-dom'

import { TickitIcon } from '../components/Reusables/SvgIcons';

function Login() {
    const auth = useContext(AuthContext);
    const navigate = useNavigate()

    const {isLoading, error, sendRequest} = useHttpClient();

    const [formState,
        inputHandler] = useForm({

        email: {
            value: '',
            isValid: false
        },
        password: {
            value: '',
            isValid: false
        }
    }, false);


    const authSubmitHandler = async event => {
        event.preventDefault();
        

        try {

            const formData = new FormData()

            formData.append("email_address", formState.inputs.email.value)
            formData.append("password", formState.inputs.password.value)

            const responseData = await
            sendRequest(`${process.env.REACT_APP_API_CALL}organiser/signin`, 'POST', formData, {});

            if(responseData){
                auth.login(responseData.data.token);
                localStorage.setItem('profileData', JSON.stringify(responseData.data));
                navigate("/dashboard")
            }
            

        } catch (err) {
            
            console.log(error)
        }
        
        
    };

  return (
    <div className='auth_bg flex justify-center pt-16'>
        <div className='mx-auto' data-aos="fade-left">
            <div className='text-center'>
                <Link to="/" className='mb-4 flex items-center justify-center'>
                    <TickitIcon width="100"/>
                </Link>
                <h3 className='font-semibold text-xl md:text-2xl text-black-text mb-4'>Organizer Login Account</h3>
                <p className='text-black-gray text-sm md:text-base font-normal'>
                    Still don't have an account? <Link to="/signup" className='text-primary font-normal hover:font-semibold'>Create an account</Link>
                </p>
            </div>

            <form className='my-6 px-4 md:px-0 md:w-96' onSubmit={authSubmitHandler}>
                <Input
                    type="email"
                    placeholder='Enter your email'
                    label="Email*"
                    labelClassName={`text-sm mb-2 font-semibold text-black-text form_login_label`}
                    id="email"
                    validators={[VALIDATOR_EMAIL()]}
                    errorText="Please enter a valid email"
                    onInput={inputHandler}
                    divClassName={`mb-3 `}
                    containerClassName={`relative  `}
                    className={`appearance-none ${formState.inputs.email.value && "bg-light-purple"}  
                    input-form p-3 text-black-text focus:outline-none 
                    focus:ring-none focus:border-button-border focus:z-10 sm:text-sm`}/>

                <Input
                    type="password"
                    placeholder='Enter your password'
                    label="Password*"
                    labelClassName={`text-sm mb-2 font-semibold text-black-text form_login_label`}
                    id="password"
                    validators={[VALIDATOR_REQUIRE()]}
                    errorText="Please enter a valid password"
                    onInput={inputHandler}
                    divClassName={`mb-3 `}
                    containerClassName={`relative  `}
                    className={`appearance-none ${formState.inputs.password.value && "bg-light-purple"}  
                    input-form p-3 w-full text-black-text focus:outline-none 
                    focus:ring-none focus:border-button-border  focus:z-10 sm:text-sm`}/>
                

                <div className='mt-0 md:mt-2 flex justify-between items-center'>
                    <label className='text-black-text text-sm md:text-base'>Remember me</label>
                    <input type="checkbox" id="remember" className='w-0 h-0 hidden check-switch'/>
                    <label className='label-switch' htmlFor='remember'></label>
                </div>

                <button type="submit" className={`flex items-center justify-center w-full 
                cursor-pointer bg-primary-dark hover:bg-primary text-white py-3 rounded-xl mt-4`}>
                    Sign In {isLoading && <span className='text-white animate-spin ml-2'><AiOutlineLoading3Quarters/></span>}
                </button>
                <div className='mt-4 w-full flex justify-center items-center'>
                    <Link to="/forgotpassword" className=' text-primary text-sm text-center font-medium'>I forgot my password</Link>
                </div>
            </form>

            <p className='mt-16 lg:mt-32 text-center text-black-tertiary'>© Copyright 2022 Tickit Limited</p>
        </div>
    </div>
  )
}

export default Login