import { useState, useCallback, useEffect } from 'react';
import { useProfile } from '../api/profile-hook';


export const useData = (isToken) => {
  const [userData, setUserData] = useState(null);
  
  // const [userId, setUserId] = useState();
  const {userProfile, profileLoading: dataLoading, profileData} = useProfile()


  const updateData = useCallback((data) => {
    console.log(data)
    setUserData(prevData => {
      return {
        ...prevData,
        ...userProfile,
        ...data
      }
    })

  },[userProfile])


  useEffect( () => {

    const fetchData = async (id) => {
      
      await profileData(`?organiser_id=${id}`)
      .then(res => {
        
        setUserData(res)
      })
      .catch(err => console.log(err))
    }

    if(localStorage.getItem('profileData') && isToken){
        const data = JSON.parse(localStorage.getItem('profileData'))
        
        fetchData(data?.organiser?.id); 
      }
    
  }, [ profileData, isToken]); 

  useEffect(() => {
    
    setUserData(userProfile)
  },[userProfile, isToken])

  return { userData, updateData, dataLoading };
};