import React, { useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import CheckComp from './components/CheckComp'
import ForgotComp from './components/ForgotComp'


function Forgotpassword() {
    const [check, setCheck] = useState(false)
    const [email, setEmail] = useState("")
    
    const checkHandler = () =>{
        setCheck(!check)
    }

    const emailHandler = (email_address) =>{
      setEmail(email_address)
  }

  return (
    <div className='auth_bg flex justify-center pt-16'>

        {!check ? <ForgotComp checkHandler={checkHandler} emailHandler={emailHandler}/> : <CSSTransition
        in={check}
        mountOnEnter
        unmountOnExit
        timeout={200}
        classNames="forgot"
      >
        <CheckComp email={email}/>
      </CSSTransition>}
      
        
    </div>
  )
}

export default Forgotpassword