import React, {useState} from 'react'
import {RiSearchLine} from 'react-icons/ri'
import EventTable from '../../Reusables/Tables/EventTable';

function EventList() {

    const [searchTerm, setSearchTerm] = useState('');

    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
    };
    
    const handleSubmit = (event) => {
        event.preventDefault();
    
    };


  return (
    <div className='my-10 mx-12 '>
        <div className=' bg-white rounded-md'>
            <div className="flex flex-col md:flex-row items-start md:items-center justify-between border-b border-border-color px-6 py-4">
                <div>
                    <h3 className='text-black-text font-medium'>Event List</h3>
                    <p className='text-black-alt text-sm'>Keep track of all event and sales</p>
                </div>

                <form onSubmit={handleSubmit}>
                    <div className="flex items-center px-2 w-full search_card bg-white ">
                        
                        <input
                            type="search"
                            value={searchTerm}
                            onChange={handleInputChange}
                            placeholder="Search event here"
                            className="p-3 rounded-lg outline-none border-none"
                        />
                        <RiSearchLine className="text-lg  text-primary-grey"/>
                    </div>
                    
                </form>

            </div>

            
            <EventTable/>
        </div>
    </div>
  )
}

export default EventList