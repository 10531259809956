import React, { useCallback, useEffect, useMemo, useState} from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';


import Footer1 from './components/Reusables/Footers/Footer1';
import ScrollToTopButton from './components/Reusables/ScrollToTopButton';

//pages
import auth from './auth';
import dashboard from './dashboard'

// installed packages
import { ToastContainer } from 'react-toastify';

//custom packages css
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

// custom hooks
import { useAuth } from './hooks/auth-hook';
import { AuthContext } from './context/auth-context';
import HeaderDashboard from './components/Reusables/Headers/HeaderDashboard';
import Loader from './UI_Elements/loader/Loader';
import { useData } from './hooks/data-hook';
import { DataContext } from './context/DataContext';

const App = () => {


  const { token, login, logout } = useAuth();

  const isToken = useMemo(() =>{
    if(token){
      return true
    }
    else{
      return false
    }
  },[token])


  
  
  // console.log(token)
  
  return(
    <AuthContext.Provider
            value={{
            isLoggedIn: !!token,
            token:token,
            login: login,
            logout: logout
    }}>

        <Router>
          <AppContent  
          isToken={isToken}
          />
        </Router>

    </AuthContext.Provider>
  )
}

const AppContent = ({isToken}) => {
  // const pathname = useLocation().pathname; 
  const {userData, updateData, dataLoading} = useData(isToken)

  return (
    <>

      <ToastContainer />
      {!isToken && (
        <>
        

        <Routes>
          <Route path="/" element={<auth.Login />} />
          <Route path='newpassword' exact element={< auth.NewPassword />}/>
          <Route path='forgotpassword' exact element={< auth.Forgotpassword />}/>
          <Route path='verification' exact element={< auth.Verification />}/>
          <Route path='/createpassword' exact element={< auth.CreatePassword />}/>
          <Route path='/signup' exact element={< auth.Signup />}/>
          <Route path='/login' exact element={< auth.Login />}/>
          <Route path='*' element={< auth.Login />}/>
        </Routes>

        </>
      )}
      {isToken && (
        <>
        <DataContext.Provider value={
          {
            userData:userData,
            updateData:updateData
          }
        }>
          <ScrollToTopButton />
          {dataLoading && <Loader title="Loading... Please wait"/>}
          { <HeaderDashboard />}
          <Routes>
            <Route path="/" element={<dashboard.DashboardOverview />} />
            <Route path="/dashboard" element={<dashboard.DashboardOverview/>} />
            <Route path="/fund-request" element={<dashboard.FundRequest/>} />
            <Route path="/fund-request/:id" element={<dashboard.FundRequestDetail/>} />
            <Route path="/settings" element={<dashboard.Settings/>} />
            <Route path="/events" element={<dashboard.Events/>} />
            <Route path="/create-event" element={<dashboard.CreateEvent/>} />
            <Route path="/event/:id" element={<dashboard.CreateEvent/>} />
            <Route path="/payments" element={<dashboard.Payments/>} />
            <Route path="/ticketers" element={<dashboard.Ticketers/>} />
            <Route path="/transactions" element={<dashboard.Orders/>} />
            {/* <Route path="/orders/:id" element={<dashboard.OrdersDetail/>} /> */}
            <Route path='*' element={< dashboard.DashboardOverview />}/>
          </Routes>
          <Footer1 />

        </DataContext.Provider>

        </>
      )}
      
    </>
  );
};

export default App;