import React from 'react'
import Orders from '../../components/DashboardFragments/Orders/Index.js'

function Index() {
  return (
    <section className=' xl:pb-[32rem] pt-[8.5rem] pb-5 px-6 md:px-12 lg:px-24'>
        <Orders/>
    </section>
  )
}

export default Index