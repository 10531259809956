import React, {useState} from 'react'
import {RiSearchLine} from 'react-icons/ri'


import RefundTable from '../../Reusables/Tables/RefundTable';

function FirstSection() {
    const [type, setType] = useState("pending")
    const [searchTerm, setSearchTerm] = useState('');

    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
      };
    
      const handleSubmit = (event) => {
        event.preventDefault();
        
      };
    
    const handleType = (type) =>{
        return setType(type)
    }

    return(
        <div className='bg-white table__border pt-8'>
        <div className="flex flex-col md:flex-row items-start md:items-center justify-between px-6">
            <div className="flex items-center mb-2 md:mb-0 w-full whitespace-nowrap overflow-x-scroll md:overflow-auto">
                <button onClick={() => handleType("pending")} className={`text-primary-grey mr-6 pb-3 ${type === "pending" && "border-b border-primary font-medium"}`}>Pending Request</button>
                <button onClick={() => handleType("approved")} className={`text-primary-grey mr-6 pb-3 ${type === "approved" && "border-b border-primary font-medium"}`}>Approved</button>
                <button onClick={() => handleType("suspended")} className={`text-primary-grey mr-3 pb-3 ${type === "suspended" && "border-b border-primary font-medium"}`}>Suspended</button>
            </div>

            <form onSubmit={handleSubmit}>
                <div className="flex items-center pl-2 fund-border bg-white ">
                    <RiSearchLine classname="text-lg  text-primary-grey"/>
                    <input
                        type="search"
                        value={searchTerm}
                        onChange={handleInputChange}
                        placeholder="Search..."
                        className="p-3 rounded-lg outline-none border-none"
                    />
                </div>
                
            </form>

        </div>

        <RefundTable/>

        </div>
    )
}

export default FirstSection