import React from 'react'
import Modal from '../../Reusables/Modal/Modal'

import { images } from '../../../assets'
import PhotoCard from './components/PhotoCard'
import { useState } from 'react';

function PhotoUpload(props) {
  const [dragEnter,
    setDragEnter] = useState(false);


  return (
    <Modal show={props.show} onCancel={props.onCancel} SubmitText="Save" CancelText="Cancel" >
        <div className='mb-6 mt-4'>
            <h3 className='font-semibold'>Upload and attach files</h3>
            <p className='text-sm'>Upload your profile picture.</p>
        </div>

        {/* Images drop zone section */}
        <div className='mb-6 web-border-8 border border-border-color border-dashed p-2'>
          <img src={images.dropImageIcon["src"]} alt={images.dropImageIcon["alt"]} className="object-contain mx-auto mb-2"/>

          <div className='flex justify-center items-center'>
            <input type="file" className='h-0 w-0' id='upload-photo'/>
            <label htmlFor="upload-photo" className='text-primary-green-dark hover:underline mr-1 font-medium'>Click to upload</label>
            <p className='text-primary-black-70 font-light'>or drag and drop</p>
          </div>
          <p className='text-primary-black-70 text-sm text-center font-light'>SVG, PNG, JPG or GIF (max. 800x400px)</p>
        </div>

        {/* Progress bar section */}
        <div className='web-border-8 border border-border-color border-dashed p-2 lg:p-3'>
          <PhotoCard/>
        </div>
        
    </Modal>
  )
}

export default PhotoUpload