import React from 'react'
import FundRequestDetail from '../../components/DashboardFragments/FundRequest/FundRequestDetails'

function Index() {
  return (
    <section className=' xl:pb-[32rem] pt-[8.5rem] pb-5 px-6 md:px-12 lg:px-24'>
        <FundRequestDetail/>
    </section>
  )
}

export default Index