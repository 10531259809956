import React, { useContext } from 'react'
import Input from '../../../UI_Elements/Input/Input'

// custom util function
import {VALIDATOR_MINLENGTH, VALIDATOR_REQUIRE} from '../../../util/validators'

// custom hooks
import {useForm} from '../../../hooks/form-hook'

import Button from '../../../UI_Elements/Button/Button';
import { businessEndpoints } from '../../../api';
import { useApi } from '../../../api/api-hook';
import { DataContext } from '../../../context/DataContext';
import { toast } from 'react-toastify';
import images from '../../../assets';

function Password() {
    const data = useContext(DataContext).userData

    console.log(data)
    const [formState,
        inputHandler] = useForm({

        oldPassword: {
            value: '',
            isValid: false
        },
        newPassword: {
            value: '',
            isValid: false
        },
        confirmPassword: {
            value: '',
            isValid: false
        }
    }, false);

    const { patchData, error, isLoading} = useApi()

    const submitHandler = async event => {
        event.preventDefault();

        if(formState.inputs.newPassword.value !== formState.inputs.confirmPassword.value){
            
            return toast.error("Passwords do not match", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
                
        }

        try {

            const formData = new FormData()
            
            formData.append('organiser_id', data.id)
            formData.append('current_password', formState.inputs.oldPassword.value)
            formData.append('new_password', formState.inputs.newPassword.value)
            formData.append('new_password_confirmation', formState.inputs.confirmPassword.value)
            
            
            const response = await patchData(businessEndpoints['UPDATE-PASSWORD'], formData, null)

            if(response){
                toast.success(response.message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    // icon: ({theme, type}) =>  <img src={images.successIcon[0]} alt={images.successIcon[1]} className="w-14 h-8"/>
                    });
                
            }

        } catch (err) {
            
            console.log(err)
        }
        
    };

  return (
    <div className='px-2 md:px-4 xl:px-8  py-4'>
        

        <form 
        onSubmit={e => submitHandler(e)}
        className='mt-2 grid grid-cols-1 gap-4 gap-x-8 '>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 gap-x-8 '>
                <div className='md:col-span-2'>
                    <Input
                        type="password"
                        placeholder='Enter your password'
                        label="Old Password"
                        labelClassName={`text-sm text-black-text mb-2 font-semibold form_login_label`}
                        id="oldPassword"
                        validators={[VALIDATOR_REQUIRE(), VALIDATOR_MINLENGTH(8)]}
                        errorText="Please enter a valid password not leass than 8 chars"
                        onInput={inputHandler}
                        divClassName={`mb-2 `}
                        containerClassName={`relative  `}
                        className={`appearance-none  
                        input-form p-3 w-full text-black-text  focus:outline-none 
                        focus:ring-none focus:border-primary focus:z-10 sm:text-sm`}/>
                </div>

                <Input
                    type="password"
                    placeholder='Enter your password'
                    label="New Password"
                    labelClassName={`text-sm text-black-text mb-2 font-semibold form_login_label`}
                    id="newPassword"
                    validators={[VALIDATOR_REQUIRE(), VALIDATOR_MINLENGTH(8)]}
                    errorText="Please enter a valid password not leass than 8 chars"
                    onInput={inputHandler}
                    divClassName={`mb-2 `}
                    containerClassName={`relative  `}
                    className={`appearance-none  
                    input-form p-3 w-full text-black-text  focus:outline-none 
                    focus:ring-none focus:border-primary focus:z-10 sm:text-sm`}/>

                <Input
                    type="password"
                    placeholder='Enter your password'
                    label="Confirm Password"
                    labelClassName={`text-sm text-black-text mb-2 font-semibold form_login_label`}
                    id="confirmPassword"
                    validators={[VALIDATOR_REQUIRE(), VALIDATOR_MINLENGTH(8)]}
                    errorText="Please enter a valid password not leass than 8 chars"
                    onInput={inputHandler}
                    divClassName={`mb-2 `}
                    containerClassName={`relative  `}
                    className={`appearance-none  
                    input-form p-3 w-full text-black-text  focus:outline-none 
                    focus:ring-none focus:border-primary focus:z-10 sm:text-sm`}/>
            </div>
            



            <div className='w-full md:col-span-2 mt-6 flex justify-end'>
                <Button type="submit"
                 bgPrimary textWhite shadow  className={`ml-auto !px-12 !bg-primary-dark hover:!bg-primary `}>
                    {isLoading ? "Saving ..." : "Save Changes"}
                </Button>
            </div>

        </form>
    </div>
  )
}

export default Password