export const businessEndpoints ={
    'COUNT-REVENUE': 'organiser/count-revenue',
    'COUNT-TICKET': 'organiser/count-tickets',
    'COUNT-TRANSACTIONS': 'organiser/count-transactions',
    'COUNT-EVENTS': 'organiser/count-events',
    'ORGANISERS-DETAILS': 'organiser/details',
    'UPDATE-ORGANISER': 'organiser/update-details',
    'UPDATE-PASSWORD': 'organiser/update-password'

};


export const transactionEndpoints ={
  'LIST-TRANSACTIONS': 'organiser/list-transactions',
  'LIST-TRANSACTIONS-PAGINATED': 'organiser/list-transactions-paginated',
  'SEARCH-TRANSACTION': 'organiser/search-transactions',
  'TICKET-DETAILS': 'ticket/details',
};


export const eventEndpoints = {
  'CREATE-EVENT': 'event/create',
  'CREATE-DRAFT': 'event/create-draft',
  'UPDATE-EVENT': 'event/update',
  'EVENT-DETAILS': 'event/details',
  'SEARCH-EVENT': 'event/search',
  'ORGANISER-EVENTS': 'organiser/list-events',
  'UPLOAD-EVENT-IMAGE': 'event/upload-image'
};