export const images = {
    youtechLogo: {
        src: require('./youtech-icon.png'),
        alt: 'youtech-logo',
    },
    logo: {
        src: require('./logo-small.png'),
        alt: 'tickit-logo',
    },
    appleLogo: {
        src: require('./mobile-log.png'),
        alt: 'apple-logo',
    },
    googlePlayLogo: {
        src: require('./google-play-logo.png'),
        alt: 'google-play-logo',
    },
    appleLogoWhite: {
        src: require('./mobile-logo-white.png'),
        alt: 'apple-logo-white',
    },
    cityImage: {
        src: require('./tickit-bg.png'),
        alt: 'city-image',
    },
    radial: {
        src: require('./radial.png'),
        alt: 'radial-alt',
    },
    radial2: {
        src: require('./radial2.png'),
        alt: 'radial-alt',
    },
    loaderImg: {
        src: require('./loader-img.png'),
        alt: 'loader-img',
    },
    passwordIcon: {
        src: require('./password-icon.png'),
        alt: 'password',
    },
    successIcon: {
        src: require('./success-icon.png'),
        alt: 'success',
    },
    mailIcon: {
        src: require('./success-icon.png'),
        alt: 'success',
    },
    profileImg: {
        src: require('./profile-img.png'),
        alt: 'success'
    },
    ticketFlyer: {
        src: require('./ticket-flyer.png'),
        alt: 'ticket-flyer'
    },
    dropImageIcon: {
        src: require('./drop-image-icon.png'),
        alt: 'drop'
    },
    ticketCode: {
        src: require('./ticketcode.png'),
        alt: 'ticket'
    },
    calendar: {
        src: require('./calendar.png'),
        alt: 'calendar'
    },
    map: {
        src: require('./map-pin.png'),
        alt: 'map-pin'
    },
    UploadCloud:{
        src: require('./upload-cloud.png'),
        alt: 'upload-cloud'
    },
    copy:{
        src: require('./copy.png'),
        alt: 'copy'
    },
    bankIcon:{
        src: require('./bank-icon.png'),
        alt: 'bank'
    },
    infoIcon:{
        src: require('./info-icon.png'),
        alt: 'info'
    },
    addUserIcon:{
        src: require('./add-icon.png'),
        alt: 'add User'
    },
    entypoTicketIcon:{
        src: require('./entypo_ticket.png'),
        alt: 'entypo ticket'
    }

};

export default images;
