import React from 'react'
import { MdOutlineArrowBack } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { images } from '../../../assets'


function ResetPasswordConfirm() {
  return (
    <div className='flex flex-col items-center text-center w-fit mx-auto' data-aos="fade-left">
        <div className='w-24 h-24 flex__center'>
            <img src={images.successIcon["src"]} alt={images.successIcon["alt"]} className=" check-bg rounded-full "/>
        </div>
        <h3 className='mb-2 mt-2 font-bold text-xl text-primary-black'>Password reset</h3>
        <p className='text-primary-black-70 text-sm'>Your password has been successfully reset.<br/> Click below to log in magically.</p>
        <button className='w-full py-2 bg-primary text-sm text-white rounded-lg mt-6'>Continue</button>
        <Link to={`/login`} className='flex__center mt-4 text-primary-black-70'>
            <MdOutlineArrowBack />
            <p >Back to login</p>
        </Link>
    </div>
  )
}

export default ResetPasswordConfirm