import React from 'react'
import images from '../../../../assets';
import { ImBin } from "react-icons/im";
import { number_format } from '../../../../util/functions';


function Ticket({id, name, quantity, price, deleteTicket}) {
  return (
    <div className='flex justify-between items-center bg-base-color-200 w-full p-4'>
        <div className='flex items-center'>
            <img src={images.entypoTicketIcon["src"]} alt={images.entypoTicketIcon['alt']}
            className='w-6 object-contain'/>
            <p className='ml-2 text-black-text font-medium text-sm'>{`${name}  >>  ${quantity} : ₦${number_format(price)}`}</p>
        </div>

        <ImBin className="text-black-text font-bold cursor-pointer" onClick={() => deleteTicket(name)}/>
    </div>
  )
}

export default Ticket