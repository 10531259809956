import React, {useMemo, useEffect, useState, useContext} from 'react'

import {useNavigate} from 'react-router-dom'

// REACT HOOKS
import {useTable, usePagination} from 'react-table'
import {AuthContext} from '../../../context/auth-context';
import moment from 'moment';


// DATA
import {EVENT_COLUMNS} from '../../../util/columns/columns';


//custom components
import Loader from '../loading/Loader';
import TablePagination from '../TablePagination/TablePagination';


// custom icons
import { GoPencil } from "react-icons/go";

// custom css
import './tables.css'
import { useApi } from '../../../api/api-hook';
import { eventEndpoints } from '../../../api';
// import Pill from '../pills/Index';




function EventTable(props) {    

    const auth = useContext(AuthContext)
    let dataStorage = null
    if(localStorage.getItem('profileData')){
        dataStorage = JSON.parse(localStorage.getItem('profileData'))
    }

    const history = useNavigate()

    const [tableData, setTableData] = useState();

    const [cellData , setCellData] = useState([]);

    const [detailsData, setDetailsData] = useState({
        "current_page" : '1',
        "page_number":"15",
        "status":"0",
        "search_text":""
    })

    const [pages, setPages] = useState([]);

    // const [refresh, setRefresh] = useState(false);

    const { getData, error, isLoading} = useApi()

    useEffect(() => {
        setTableData(null)

        const formData = new FormData()
        formData.append('page_number',detailsData.page_number);
        formData.append('status',detailsData.status);


        const fetchData = async() => {
            
            try {
                const response = await getData(eventEndpoints['ORGANISER-EVENTS'], null,`?organiser_id=${dataStorage?.organiser?.id}`);

                setTableData(response.data);
                // setPages(responseData.claim.link)

                if(response){
                    setCellData([])
                    for(let file of response.data.events){
                        setCellData((cell)=>[...cell, {
                            "id":file.id,
                            "dateCreated": moment(file?.createdAt).format('DD MMM YYYY') || "",
                            "eventDate": moment(file?.start_time).format('ddd, MMM D') || "",
                            "time": moment(file?.start_time).format('h.mm A') || "",
                            "location": `${file?.address} ${file?.state}` || "",
                            "eventName": file?.name || "",
                            "issued": Object.keys(file?.tickets).length || "0",
                            "tickets": Object.keys(file?.availability).length || "0"
                        }])
                    }
                }



            } catch (err) {
                console.log(error)
            }
        };
        fetchData();

    }, [getData, error, auth, detailsData, dataStorage.organiser.id]);





    const pageChangeHandler = (number) =>{
        setDetailsData({
            ...detailsData,
            "current_page":detailsData.current_page + number
        })
    }

    const pageSizeChangeHandler = () => {
        setDetailsData({
            ...detailsData,
            "page_number":detailsData.page_number + 10
        })
    }   

    const eventHandler = (id) => {
        history(`/event/${id}`)
    }


    const columns = useMemo(() => EVENT_COLUMNS, [])
    const data = useMemo(() => cellData, [cellData])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable({
        columns,
        data,
        initialState: {
            pageIndex: 0
        }
    }, usePagination)

    return (
        <>

        {!isLoading && cellData.length === 0 && 
        <div className='py-16 px-6 w-full flex justify-center items-center'>
            <p className='text-black-alt text-sm text-center'>No event has been created yet, create some to<br/> start seeing all your events list here</p>
        </div>}
        
        {isLoading && <Loader/>}

        {cellData.length !== 0 &&
        <div className="bg-white pb-8 table__borderless mt-6 overflow-x-auto w-full">
             
            <table {...getTableProps()} className="w-full text-left">
                <thead className='border-b border-border-color w-full'>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} className="">
                            {headerGroup
                                .headers
                                .map(column => (
                                    <th {...column.getHeaderProps()} 
                                    className={`text-xs md:text-sm text-primary-black font-medium capitialize 
                                    whitespace-nowrap p-4 px-6`}>
                                        {column.render('Header')}
                                    </th>
                                ))}
                            
                            <th className="text-xs md:text-sm text-primary-black-70 font-medium whitespace-nowrap p-4"></th>
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, index) => {
                        prepareRow(row)

                        return (
                            <tr {...row.getRowProps()} className='bg-odd-row font-sora'>
                               
                                {row
                                    .cells
                                    .map(cell => {
                                        
                                        return <td {...cell.getCellProps()} 
                                        className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4 text-left text-primary-grey">
                                            {cell.render('Cell')}
                                        </td>
                                    })}
                                
                                {/* <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4">
                                    <GoPencil className='text-primary-black text-lg cursor-pointer' 
                                    onClick={() => eventHandler(row.original.id)}/>
                                </td> */}
                            </tr>
                        )
                    })}
                    
                </tbody>

            </table>
            <div className='w-full dash-h-1 bg-primary-black opacity-50'></div>
            {tableData && (
                <TablePagination 
                current_page = {detailsData.current_page}
                last_page = {tableData.last_page}
                prev_page_url = {tableData.prev_page_url}
                next_page_url = {tableData.next_page_url}
                // pages = {pages}
                per_page = {detailsData.page_number}
                first_page_url = {tableData.first_page_url}
                last_page_url={tableData.last_page_url}
                pageChangeHandler={pageChangeHandler}
                pageSizeChangeHandler={pageSizeChangeHandler}/>
           )}

            
            
        </div>
        }
        </>              
    )
}

export default EventTable