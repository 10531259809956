import React, {useEffect, useState} from 'react'
import {RiSearchLine} from 'react-icons/ri'


import RefundTable from '../../Reusables/Tables/RefundTable';
import ReactDatePicker from 'react-datepicker';

import { BsCalendar4 } from 'react-icons/bs';
import Button from '../../Reusables/Button/Button';
import PaymentModal from '../../Reusables/Modal/PaymentModal';
import SalesTable from '../../Reusables/Tables/SalesTable';
import WithdrawalTable from '../../Reusables/Tables/WithdrawalTable';
import { useTransactions } from '../../../api/transactions-hook';

function FirstSection({data}) {
    const [type, setType] = useState("sales")
    const [filterDate, setFilterDate] = useState('');
    const [showModal, setShowModal] = useState('')

    console.log(data)

    const {transactionsLoading, getTransactions} = useTransactions()

    useEffect(() => {
        
        getTransactions('LIST-TRANSACTIONS',`?organsier_id=${data?.id}`).then(res => {
        console.log(res)
        })
    },[])
    
    const handleSubmit = (event) => {
    event.preventDefault();
    
    };
    
    const handleType = (type) =>{
        return setType(type)
    }

    const ModalCancelHandler = () =>{
        setShowModal('')
    }

    return(

        <>
        {/* Table */}<PaymentModal show={showModal==="payment"} onCancel={ModalCancelHandler}/>
        <div className='bg-white table__border pt-8'>
        <div className="flex flex-col md:flex-row items-start md:items-center justify-between px-6">
            <div className="flex items-center mb-2 md:mb-0 w-full whitespace-nowrap overflow-x-scroll md:overflow-auto">
                <button onClick={() => handleType("sales")} className={`text-primary-grey mr-6 pb-3 ${type === "sales" && "border-b border-primary font-semibold"}`}>Recent Sales</button>
                <button onClick={() => handleType("withdrawal")} className={`text-primary-grey mr-6 pb-3 ${type === "withdrawal" && "border-b border-primary font-semibold"}`}>Withdrawal</button>
            </div>

            <div className='flex items-center justify-end w-full'>
                <div className={`event-input text-black flex justify-end items-center w-56 mr-4`}>
                    <ReactDatePicker
                    selected={filterDate}
                    onChange={(date) => setFilterDate(date)}
                    placeholderText="Filter by date"
                    className="!w-full focus:outline-none italic"
                    />
                    <BsCalendar4/>
                </div>

                <Button textWhite className={`bg-primary-dark`} onClick={() => setShowModal("payment")}>+ Transfer Money</Button>
            </div>

        </div>

        {type === "sales" && <SalesTable/>}
        {type === "withdrawal" && <WithdrawalTable/>}

        </div>
        </>
    )
}

export default FirstSection