import React, { useEffect, } from 'react';
import ReactDOM from 'react-dom';

// imported react hooks and packages
import { CSSTransition } from 'react-transition-group';

// custom components
import Backdrop from '../../../../UI_Elements/Backdrop/Backdrop';
import Button from '../../../../UI_Elements/Button/Button';

// custom hooks
// import {useHttpClient} from '../../../../hooks/less-http-hook';
// import {AuthContext} from '../../../../context/auth-context';

// custom icons
import { VscChromeClose } from 'react-icons/vsc';

// custom css
import '../../../Reusables/Modal/Modal.css';
// import { images } from '../../../../assets'

import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { BsArrowLeftShort } from 'react-icons/bs';

const validationSchema = Yup.object().shape({
  reason: Yup.string().required("reason is required"),
  bank: Yup.string().required("bank is required"),
  accountNumber: Yup.number().required("Account number is required")
});

const RefundModalOverlay = props => {

    // const auth = useContext(AuthContext)
    // const { error, sendRequest} = useHttpClient();
    const initialValues = {
        reason: "",
        bank: "",
        accountNumber: "",
      };

  useEffect(() => {
    return
  }, [props.refresh])


//   const handleSwitch = (type) =>{
//     setToggle((prevState) => {
//         const updatedToggle = {};
    
//         for (const key in prevState) {
//           if(prevState[key] === true && key === type){
//             updatedToggle[key] = false
//           }
//           else{
//             updatedToggle[key] = key === type
//           }
//         }
    
//         return updatedToggle;
//       });
//   }

  const handleSubmit = async(values, { setSubmitting }) => {
    console.log(values)
    
    // try {

    //     const data = JSON.stringify({
    //         firstname: values?.firstname,
    //         lastname: values?.lastname,
    //         email: values?.email,
    //         phone_number: values?.phone,
    //         permissions: permissions,
    //     })

    //     const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}admin/create`, 'POST', data, {Authorization: 'Bearer '+auth.token, 'Content-Type': 'application/json'});

    //     if(responseData){
          
    //         props.onSubmit()
    //     }
        

    // } catch (err) {
        
    //     console.log(error)
    // }

  };

  const content = (
    <div className={`dashboard-modal dashboard-modal-80 px-2 md:px-4
    ${props.loading && "dashboard-modal-loading"} 
    ${props.className} ${props.modalTopRight && 'dashboard-modal-top-right'}`}  
    style={props.style}>
      {/* <header className={`dashboard-modal__header ${props.headerClass}`}>
        <h2>{props.header}</h2>
      </header> */}
    <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
            <div className={`dashboard-modal__content ${props.contentClass}`}>
                <div className="flex items-start flex-col">

                    <h4 className='mb-2 mt-10 w-full text-black-text text-xl font-bold text-center'>Request for refund</h4>
                    <h4 className='mb-4 text-primary-grey text-sm text-center'>
                        Refund will only be approved if you are requesting before the event date
                    </h4>

                    {/* <hr className='mb-4 w-full' style={{borderTop:"1px solid #E4E7EC !important"}}/> */}

                    <div className='w-full my-3'>
                        <div>
                            <label htmlFor="reason">Reason for refund</label>
                            <Field className="form__input font-sora w-full py-2 px-2" type="text" id="reason" 
                            name="firstname" placeholder="Enter reason for refund"/>
                            <ErrorMessage className="text-xs" name="reason" component="div" />
                        </div>

                        <div className='my-4'>
                            <label htmlFor="bank">Bank</label>
                            <Field className="form__input font-sora w-full py-2 px-2" as="select" id="bank" 
                            name="bank" >
                                <option value="">Select a bank</option>
                                <option value="Firstbank">First Bank</option>
                                <option value="accessbank">Access bank</option>
                            </Field>
                            <ErrorMessage className="text-xs" name="bank" component="div" />
                        </div>

                        <div className='my-4'>
                            <label htmlFor="accountNumber">Account Number</label>
                            <Field className="form__input font-sora w-full py-2 px-2" type="number" id="accountNumber" 
                            name="accountNumber" placeholder="Enter Account Number"/>
                            <ErrorMessage className="text-xs" name="accountNumber" component="div" />
                        </div>
                        
                    </div>

                    
                    <div className='refund_details p-3 w-full'>
                        <p className="text-black-text text-sm mb-1">UBA</p>
                        <p className="text-black-text text-sm mb-1">Allen Evwidonor</p>
                        <p className="text-black-text text-sm">2083400230</p>
                    </div>

                    
                </div>
            {props.children}
            </div>
            <footer className={`dashboard-modal__footer w-full flex justify-end items-center ${props.footerClass}`}>
            {/* <Button border shadow className={`${props.cancelClassName}`} onClick={props.onCancel}>Cancel</Button> */}
            <Button bgPrimary textWhite className={`w-full ${props.submitClassName}`}  
            disabled={isSubmitting} type="submit">Send Request</Button>
            </footer>
        </form>
    
    )}
      </Formik>

      {/* close button */}
      <div className={`absolute top-4 left-4 ${props.closeButton ? 'block':'hidden'}`}>
          <button onClick={props.onCancel}>
              <BsArrowLeftShort
                  className='text-xl text-dashboard-black rounded pointer'/>
          </button>
      </div>
    </div>
  );
  return ReactDOM.createPortal(content, document.getElementById('modal-hook'));
};

const RefundModal = props => {
  return (
    <>
      {props.show && <Backdrop onClick={props.onCancel} fade={true}/>}
      <CSSTransition
        in={props.show}
        mountOnEnter
        unmountOnExit
        timeout={200}
        classNames="dashboard-modal"
      >
        <RefundModalOverlay {...props} />
      </CSSTransition>
    </>
  );
};

export default RefundModal;
